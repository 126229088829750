import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Component } from "react";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";

interface QrCodePageProps { }

interface QrCodePageState {
  type: "tree-tag" | "donation";
  scout_id: number;
}

class QrCodePage extends Component<QrCodePageProps, QrCodePageState> {
  constructor(props: QrCodePageProps) {
    super(props);
    this.state = {
      type: "tree-tag",
      scout_id: localStorage.getItem("scout_id") ? parseInt(localStorage.getItem("scout_id")!) : 0
    };
  }

  getURL() {
    if (this.state.type === "tree-tag") {
      return "https://recyclemytree.org/products/recycle-my-tree?scout-id=" + this.state.scout_id;
    } else if (this.state.type === "donation") {
      return "https://recyclemytree.org/products/make-a-donation?scout-id=" + this.state.scout_id;
    } else {
      return "https://recyclemytree.org";
    }
  }

  render() {
    return <div className="text-center pt-2 mb-5">
      <div className="w-100">
        <ToggleButtonGroup
          color="primary"
          value={this.state.type}
          exclusive
          onChange={(event, newValue) => {
            if (newValue) {
              this.setState({ type: newValue });
            }
          }}
          aria-label="Platform"
        >
          <ToggleButton value="tree-tag" aria-label="Tree Tag">
            Tree Tag
          </ToggleButton>
          <ToggleButton value="donation" aria-label="Donation">
            Donation
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="h-auto mx-auto w-100 p-5 bg-light rounded my-2" style={{ maxWidth: "400px" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={this.getURL()}
          viewBox={`0 0 256 256`}
        />
      </div>
      <p className="text-muted mt-0">Your Scout ID is <pre className="d-inline">
        {localStorage.getItem("scout_id")}
      </pre></p>
      <button className="btn btn-primary" onClick={() => this.shareURL()}>Share</button>
    </div>;
  }

  shareURL() {
    if (navigator.share) {
      navigator.share({
        title: this.state.type === "tree-tag" ? "Recycle My Tree" : "Make a Donation",
        text: "Scan this QR code to " + (this.state.type === "tree-tag" ? "order a tree tag from" : "make a donation to") + " Troop 600.",
        url: this.getURL(),
      })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      toast.error("Sharing is not supported on this device.", { id: "error" });
    }
  }
}

export default QrCodePage;
