import { Component } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { callAPI, unCacheAPI } from "../../utils/API";
import { Driver } from "./ManageDrivers";
import UserTagify from "../quads/UserTagify";

interface EditDriverModalProps {
  driver: Driver | null;
  onClose: () => void;
  onSave: () => void;
}

interface EditDriverModalState {
  drivers: number[];
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class EditDriverModal extends Component<
  EditDriverModalProps,
  EditDriverModalState
> {
  constructor(props: EditDriverModalProps) {
    super(props);
    this.state = {
      drivers: [],
    };
  }

  save() {
    const driver_id = this.props.driver?.id;
    let new_user_id = this.state.drivers[0];
    if (!driver_id) return;
    if (!new_user_id) new_user_id = 0;

    callAPI("address/", "POST", {
      action: "edit_driver",
      id: driver_id,
      user_id: new_user_id,
    }).then(() => {
      unCacheAPI("pickup/drivers");
      this.props.onClose();
      this.props.onSave();
    });
  }

  render() {
    return (
      <Modal
        open={this.props.driver !== null}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addEditDriverModalLabel">
                Change Driver
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="driver">Driver</label>
                  <UserTagify
                    defaultValue={JSON.stringify([this.props.driver?.user_id])}
                    onChange={(value) => {
                      const drivers = JSON.parse(value);
                      this.setState({ drivers });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  this.save();
                }}
                disabled={this.state.drivers.length > 1}
              >
                Assign
              </button>
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default EditDriverModal;
