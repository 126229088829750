import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import UserTagify from "../quads/UserTagify";

interface MoveResponsesProps {
}

interface MoveResponsesState {
  userSelected: number;
}

class MoveResponses extends Component<MoveResponsesProps, MoveResponsesState> {
  constructor(props: MoveResponsesProps) {
    super(props);
    this.state = {
      userSelected: -1,
    };
  }

  moveResponses() {
    callAPI("user/", "POST", {
      action: "move_responses",
      user_id: this.state.userSelected,
    })
      .then((res) => {
        toast.success("Responses moved successfully");
        this.setState({
          userSelected: -1,
        });
      })
      .catch((err) => {
        toast.error("Error moving responses");
        console.log(err);
      });
  }

  render() {
    return (
      <div className="container mt-2 mb-5">
        <h1 className="fw-normal h3">Move Responses</h1>
        <p className="text-muted mb-1">
          If you have been entering responses for a different user, you can move them to their account here.
        </p>
        <p className="text-muted mb-1">
          Select a user to move responses to:
        </p>
        <UserTagify onChange={(val) => {
          const users = JSON.parse(val);
          if (users.length === 1) {
            this.setState({
              userSelected: users[0],
            });
          } else {
            this.setState({
              userSelected: -1,
            });
          }
        }} />
        <button
          className="btn btn-primary mt-2"
          disabled={this.state.userSelected === -1}
          onClick={() => this.moveResponses()}
        >
          Move Responses
        </button>
      </div>
    );
  }
}

export default MoveResponses;
