import { Coordinate } from "mapbox-gl";
import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";
import QuadCard from "./QuadCard";

interface QuadsPageProps { }

interface QuadsPageState {
  quads: Quad[];
  loaded: boolean;
  search: string;
}

export interface Quad {
  assigned_to: string;
  polygon: Coordinate[];
  quad_id: number;
  addresses_in_quad: number;
  addresses_visited: number;
  money: number;
  money_last_year: number;
  money_by_scout: MoneyByScout[];
}

interface MoneyByScout {
  user_id: number;
  total_money: number;
  name: string;
}

class QuadsPage extends Component<QuadsPageProps, QuadsPageState> {
  constructor(props: QuadsPageProps) {
    super(props);
    this.state = {
      quads: [],
      loaded: false,
      search: "",
    };
  }

  loadQuads() {
    callAPI("quads/").then((data: any) => {
      this.setState({ quads: data.data, loaded: true });
    });
  }

  componentDidMount() {
    this.loadQuads();
  }

  render() {
    if (!this.state.loaded) {
      const NUM_PLACEHOLDERS = 20;
      return (
        <div className="container">
          <input
          type="text"
          placeholder="Search"
          className="form-control m-2"
          disabled
        />
        <Link to="/manage/quads/map" className="btn btn-primary m-2">
          View Master Map
        </Link>
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
            {Array(NUM_PLACEHOLDERS)
              .fill(0)
              .map((_, i) => (
                <div className="col" key={i}>
                  <div className="card m-2">
                    <div className="card-body">
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <input
          type="text"
          placeholder="Search"
          value={this.state.search}
          onChange={(e) => this.setState({ search: e.target.value.toLowerCase() })}
          className="form-control m-2"
        />
        <Link to="/manage/quads/map" className="btn btn-primary m-2">
          View Master Map
        </Link>
        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
          {this.state.quads.map((quad) => {
            if (this.state.search) {
              const propsToSearch: (keyof Quad)[] = ["assigned_to", "quad_id"];
              let found = false;
              for (const prop of propsToSearch) {
                if (quad[prop].toString().toLowerCase().includes(this.state.search)) {
                  found = true;
                }
              }
              if (!found) {
                return null;
              }
            }
            return <QuadCard key={"quad_" + quad.quad_id} quad={quad} editable />;
          })}
        </div>
      </div>
    );
  }
}

export default QuadsPage;
