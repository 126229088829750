import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";
import QuadCard from "../quads/QuadCard";
import { Quad } from "../quads/QuadsPage";
import ResponseChart from "./ResponseChart";
import RewardLevelsModal from "./RewardLevelsModal";

interface DashboardProps { }

interface DashboardState {
  stats: TreeTagStats | null;
  assigned_quads: Quad[] | null;
  levelsModalOpen: boolean;
}

interface TreeTagStats {
  total_sales: number;
  total_tags: number;
  avg_donation: number;
  goal: number;
  manual_total: number;
  reward_levels: TreeTagRewardLevel[];
}

export interface TreeTagRewardLevel {
  draws: number;
  name: string;
  msg: string;
  rate: number;
  threshold: number;
}

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      stats: null,
      assigned_quads: null,
      levelsModalOpen: false,
    };
  }

  loadStats() {
    callAPI("your-stats/")
      .then((response: any) => {
        this.setState({ stats: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  loadQuads() {
    callAPI("quads/?assigned")
      .then((response: any) => {
        this.setState({ assigned_quads: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadStats();
    this.loadQuads();
  }

  getBadgeColor(level: string | undefined) {
    switch (level) {
      case "Bronze":
        return "#28a745";
      case "Silver":
        return "#c0c0c0";
      case "Gold":
        return "#ffc107";
      case "Platinum":
        return "#007bff";
      case "Diamond":
        return "#dc3545";
      default:
        return "#28a745";
    }
  }

  getReward(): TreeTagRewardLevel {
    let reward: TreeTagRewardLevel = {
      draws: 0,
      name: "",
      msg: "Calculating your rewards...",
      rate: 0,
      threshold: 0,
    };
    let nextReward: TreeTagRewardLevel | null = null;

    if (this.state.stats) {
      let total = this.state.stats.total_sales;
      for (let i = 0; i < this.state.stats.reward_levels.length; i++) {
        let level = this.state.stats.reward_levels[i];
        if (total >= level.threshold) {
          reward = level;
          nextReward = this.state.stats.reward_levels[i + 1] || null;
        }
      }

      if (nextReward) {
        let moreSales = nextReward.threshold - total;
        reward.msg = `Sell $${moreSales} more to keep ${nextReward.rate * 100}% of your money and get ${nextReward.draws - reward.draws} extra draws!`;
      } else {
        reward.msg = "Holy smokes! You've reached the highest level. Keep it up!";
      }
    }

    return reward;
  }

  render() {
    const leftToGoal = this.state.stats
      ? this.state.stats?.goal - this.state.stats?.total_sales
      : 0;
    const reward = this.getReward();
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h2>Your Goal</h2>
                {this.state.stats?.goal ? (
                  <div>
                    <p className="lead mb-0">
                      {formatter.format(
                        this.state.stats?.total_sales as number
                      )}{" "}
                      / {formatter.format(this.state.stats?.goal as number)}
                      <span className="small text-muted">
                        <button
                          className="btn btn-link p-0 ms-2 mb-1"
                          onClick={() => {
                            this.setGoal();
                          }}
                        >
                          Edit
                        </button>
                      </span>
                    </p>
                    <p>
                      {leftToGoal <= 0
                        ? "Congrats! You've reached your goal."
                        : formatter.format(leftToGoal) +
                        " left to reach your goal."}
                    </p>
                  </div>
                ) : (
                  <p>
                    Ready to set a goal? Click{" "}
                    <button
                      className="btn btn-link p-0 mb-1"
                      onClick={() => {
                        this.setGoal();
                      }}
                    >
                      here
                    </button>{" "}
                    to set one.
                  </p>
                )}
                <hr />
                <h2>
                  Estimated Earnings{" "}
                  <span
                    className="badge"
                    style={{
                      backgroundColor: this.getBadgeColor(
                        reward.name
                      ),
                    }}
                    title={reward.name}
                  >
                    {reward.name}
                  </span>
                </h2>
                <h1 className="display-4">
                  {this.state.stats &&
                    formatter.format(
                      this.state.stats?.total_sales *
                      reward.rate
                    )}
                </h1>
                <p className="text-muted">
                  🎁 {reward.draws} Gift Card Drawings
                </p>
                <div className="alert alert-info">
                  <p>{reward.msg} <button className="btn btn-link" onClick={() => {
                    this.setState({ levelsModalOpen: true });
                  }}>Learn more</button></p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 row">
                <div className="col-lg-6 col-sm-12">
                  <h2>
                    Your Stats{" "}
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.loadStats();
                      }}
                    >
                      Refresh
                    </button>
                  </h2>
                  <h4>
                    Total Sales:{" "}
                    {formatter.format(this.state.stats?.total_sales as number)}
                  </h4>

                  <h4>Total Tree Tags Sold: {this.state.stats?.total_tags}</h4>
                  <h4>
                    Avg Donation:{" "}
                    {formatter.format(this.state.stats?.avg_donation as number)}
                  </h4>
                  {/* <h4>Avg Tag Sale: {formatter.format(-1)}</h4> */}
                </div>
                <div className="col-lg-6 col-sm-12">
                  <ResponseChart />
                </div>
                <div className="col-12">
                  <hr />
                  <h2>Efficiency</h2>
                  <p className="small text-muted">
                    Track efficiency by recording hours worked
                  </p>
                  <Link
                    to="/efficiency"
                    className="btn btn-outline-primary"
                  >
                    Track Efficiency
                  </Link>
                </div>
              </div>
            </div>
            <Card variant="outlined" className="mt-2 p-3">
              <Link
                to="/list"
                className="btn btn-outline-primary me-2 mt-2"
              >
                Enter Tags
              </Link>
              <button
                className="btn btn-outline-secondary me-2 mt-2"
                onClick={() => {
                  this.loadQuads();
                }}
              >
                Refresh Quads
              </button>
              <hr />
              <h3>Your Quads</h3>
              <p>
                Your Scout ID is{" "}
                <pre className="d-inline">
                  {localStorage.getItem("scout_id")}
                </pre>
              </p>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {this.state.assigned_quads?.map((quad) => (
                  <QuadCard key={quad.quad_id} quad={quad} />
                ))}
                <div className="col">
                  <Card variant="outlined" className="m-2 p-3">
                    <h2>Manual Entries</h2>
                    <h3>
                      {formatter.format(
                        this.state.stats?.manual_total || 0
                      )}
                    </h3>
                    <Link
                      className="btn btn-outline-primary m-2"
                      to="/manual"
                    >
                      View Entries
                    </Link>
                  </Card>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <RewardLevelsModal
          levels={this.state.stats?.reward_levels || []}
          open={this.state.levelsModalOpen}
          onClose={() => {
            this.setState({ levelsModalOpen: false });
          }}
        />
      </div>
    );
  }

  setGoal() {
    let ammount = prompt("Enter your goal in dollars");
    if (ammount && parseFloat(ammount)) {
      callAPI("your-stats/", "POST", { goal: parseFloat(ammount) }).then(
        (data) => {
          unCacheAPI("your-stats/");
          this.loadStats();
        }
      );
    } else {
      toast.error("Please enter a valid number", { id: "error" });
    }
  }
}

export default Dashboard;
