import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, unCacheAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";
import EfficiencyModal from "./EfficiencyModal";

interface EfficiencyPageProps { }

interface EfficiencyPageState {
  entries: EfficiencyEntry[];
  loaded: boolean;
  addModalOpen: boolean;
}

interface EfficiencyEntry {
  hours: number;
  money: number;
  date: string;
  id: number;
}

class EfficiencyPage extends Component<
  EfficiencyPageProps,
  EfficiencyPageState
> {
  constructor(props: EfficiencyPageProps) {
    super(props);
    this.state = {
      entries: [],
      loaded: false,
      addModalOpen: false,
    };
  }

  loadEntries() {
    callAPI("hours/").then((data: any) => {
      this.setState({ entries: data.data, loaded: true });
    });
  }

  componentDidMount() {
    this.loadEntries();
  }

  render() {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <h1 className="fw-normal">Your Efficiency</h1>
            <p className="text-muted">Enter your hours here to compare efficiency between days.</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.setState({ addModalOpen: true })}
            >
              Log Hours
            </button>
            <hr />
          </div>
          <div className="col-12">
            <div className="row">
              {this.state.entries.map((entry) => {
                return (
                  <div className="col-12 p-3" key={entry.id}>
                    <Card variant="outlined" className="p-3">
                      <p className="text-muted">
                        {new Date(entry.date).toLocaleDateString()}
                      </p>
                      <p className="card-text">
                        <span className="display-4">
                          {formatter.format(entry.money / entry.hours)}
                        </span>
                        {" / hr"}
                      </p>
                      <p>
                        {formatter.format(entry.money)} in {entry.hours} hrs
                      </p>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.deleteEntry(entry.id);
                        }}
                      >
                        Delete
                      </button>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <EfficiencyModal
          open={this.state.addModalOpen}
          onClose={() => this.setState({ addModalOpen: false })}
          onSave={() => {
            unCacheAPI("hours/");
            this.loadEntries();
          }}
        />
      </div>
    );
  }

  deleteEntry(id: number) {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      callAPI("hours/", "POST", {
        id: id,
        action: "delete",
      })
        .then(() => {
          unCacheAPI("hours/");
          this.loadEntries();
        })
        .catch((err) => {
          toast.error("Error deleting entry", { id: "error" });
        });
    }
  }
}

export default EfficiencyPage;
