import Map, {
  GeolocateControl,
  Source,
  Layer,
  MapProvider,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import { House } from "./QuadPage";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React from "react";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import toast from "react-hot-toast";
import { MAP_BOUNDS } from "../../utils/Params";
import Card from "@mui/material/Card";

// fix transpilation
(mapboxgl as any).workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

const layers = [
  { name: "old_donation", color: "#86689c", outline: "#e8d900" },
  { name: "old_refused", color: "#8f8c56", outline: "#e8d900" },
  { name: "old_no_answer", color: "#8f8c56", outline: "#e8d900" },
  { name: "old_other", color: "#eeeeee", outline: "#e8d900" },
  { name: "old_tag", color: "#609c73", outline: "#e8d900" },
  { name: "new_donation", color: "#6a0dad", outline: "#000000" },
  { name: "new_refused", color: "#9c0505", outline: "#000000" },
  { name: "new_no_answer", color: "#ff9100", outline: "#e8d900" },
  { name: "new_no_answer_today", color: "#ff9100", outline: "#000000" },
  { name: "new_tag", color: "#059c31", outline: "#000000" },
  { name: "new_other", color: "#000000", outline: "#000000" },
  { name: "house_generic", color: "#828282", outline: "#e8d900" },
];

interface QuadMapProps {
  theme: "light" | "dark";
  quad: number;
  assignedQuads: number[];
  houses: House[];
  quadBorder: any;
  onHouseClick: (house: House) => void;
  onRefreshRequest: () => void;
  houseSelected?: House | null;
}

interface QuadMapState {
  legend: boolean;
}

let map: any = null;
const geocoder = new MapboxGeocoder({
  accessToken: MAPBOX_ACCESS_TOKEN,
  mapboxgl: mapboxgl,
  marker: false, // Do not use the default marker style
  placeholder: "Search by address...", // Placeholder
  types: "address",
  bbox: [-122.253, 47.561, -122.136, 47.675],
  proximity: {
    longitude: -122.2107,
    latitude: 47.6283
  }
});

class QuadMap extends React.Component<QuadMapProps, QuadMapState> {
  constructor(props: QuadMapProps) {
    super(props);
    this.state = {
      legend: !localStorage.getItem("legend"),
    };
    geocoder.on("result", (event) => {
      this.addAddressAndOpen(event.result);
    });
    localStorage.setItem("quad", props.quad.toString());
  }

  componentDidMount() {
    map = null;
  }

  componentDidUpdate(prevProps: QuadMapProps): void {
    localStorage.setItem("quad", this.props.quad.toString());
    if (
      map &&
      this.props.quadBorder !== prevProps.quadBorder &&
      this.props.quadBorder
    ) {
      try {
        // center map on quad border
        let polygon = turf.polygon([this.props.quadBorder]);
        let bbox = turf.bbox(polygon);
        map.fitBounds(bbox, {
          padding: 50,
        });
      } catch (e) { }
    }
    if (map && this.props.houseSelected !== prevProps.houseSelected) {
      map.resize();
    }
  }

  render() {
    let quadBorderGeoJSON: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [this.props.quadBorder],
      },
      properties: null,
    };
    return (
      <div className="map">
        <MapProvider>
          <nav id="menu">
            <button
              className="btn btn-light mt-3 mt-md-2 me-2 float-end"
              onClick={() => {
                this.props.onRefreshRequest();
                toast.success("Refreshed map data");
              }}
              title="Refresh"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
            <div className="btn-group mt-2 me-2 float-end">
              <button
                type="button"
                className="btn btn-light dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {(this.props.quad !== -1)
                  ? `Quad #${this.props.quad}`
                  : "All Quads"}
              </button>
              <ul className="dropdown-menu">
                {this.props.assignedQuads.map((quad) => {
                  return (
                    <Link
                      key={quad}
                      className="dropdown-item"
                      to={"/map/sales/" + quad + "/"}
                    >
                      Quad #{quad}
                    </Link>
                  );
                })}
                <Link
                  key={"all"}
                  className="dropdown-item"
                  to={"/map/sales/"}
                >
                  All
                </Link>
              </ul>
            </div>
            {!this.state.legend && (
              <button
                className="btn btn-light mt-3 mt-md-2 me-2 float-end"
                onClick={() => {
                  this.setState({ legend: true });
                  localStorage.removeItem("legend");
                }}
                title="Show legend"
              >
                <i className="bi bi-list-ul"></i>
              </button>
            )}
          </nav>
          {this.state.legend && (
            <Card className="legend">
              <div
                className="legend-header-close"
                onClick={() => {
                  localStorage.setItem("legend", "false");
                  this.setState({ legend: false });
                }}
              >
                <span> &times; </span>
              </div>
              <h4>Legend</h4>
              <div>
                <span
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #e8d900",
                  }}
                ></span>
                Need to visit
              </div>
              <div>
                <span
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #000000",
                  }}
                ></span>
                Don't need to visit
              </div>
              <hr className="my-1" />
              <div>
                <span style={{ backgroundColor: "#059c31" }}></span>Tree Tag
              </div>
              <div>
                <span style={{ backgroundColor: "#6a0dad" }}></span>Donation
              </div>
              <div>
                <span style={{ backgroundColor: "#ff9100" }}></span>No Answer
              </div>
              <div>
                <span style={{ backgroundColor: "#9c0505" }}></span>Refused
              </div>
              <div>
                <span style={{ backgroundColor: "#000000" }}></span>Other
              </div>
              <div>
                <span style={{ backgroundColor: "#828282" }}></span>No Data
              </div>
            </Card>
          )}
          <Map
            id="map"
            ref={(ref) => {
              if (ref && !map) {
                map = ref.getMap();
                // Do not add geocoder multiple times
                if (
                  !map._controls.some((control: any) => control.geocoderService)
                ) {
                  map.addControl(geocoder);

                  map.on('idle', function () {
                    map.resize()
                  });

                  if (this.props.quadBorder) {
                    try {
                      // center map on quad border
                      let polygon = turf.polygon([this.props.quadBorder]);
                      let bbox = turf.bbox(polygon);
                      map.fitBounds(bbox, {
                        padding: 50,
                      });
                    } catch (e) { }
                  }
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: -122.21,
              latitude: 47.63,
              zoom: 13,
            }}
            mapStyle={this.props.theme === "light" ?
              "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
              "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
            onClick={(e) => {
              const features = map?.queryRenderedFeatures(e.point);
              if (features) {
                const displayProperties = [
                  "type",
                  "properties",
                  "id",
                  "layer",
                  "source",
                  "sourceLayer",
                  "state",
                ];
                const displayFeatures = features.map((feat: any) => {
                  const displayFeat: any = {};
                  displayProperties.forEach((prop) => {
                    displayFeat[prop] = feat[prop];
                  });
                  return displayFeat;
                });
                const clickedCoordinates = e.lngLat;
                this.addrClick(displayFeatures, clickedCoordinates);
              }
            }}
            maxBounds={MAP_BOUNDS}
          >
            <GeolocateControl
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />
            {this.props.quadBorder && (
              <Source id="quad" type="geojson" data={quadBorderGeoJSON}>
                <Layer
                  id="quad_border"
                  type="line"
                  paint={{
                    "line-color": "#f00",
                    "line-width": 3,
                  }}
                />
                <Layer
                  id="quad_fill"
                  type="fill"
                  paint={{
                    "fill-color": "#0080ff",
                    "fill-opacity": 0.05,
                  }}
                />
              </Source>
            )}
            {layers.map((layer) => {
              let geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: "FeatureCollection",
                features: this.props.houses
                  .filter((house) => house.layerType === layer.name)
                  .map((house) => {
                    const houseCoordinates = [house.lat, house.lng];
                    const houseID = house.id;
                    const houseAddress = house.address;
                    return {
                      type: "Feature",
                      properties: {
                        house: house,
                        layerType: house.layerType,
                        description: houseAddress,
                        icon: "house",
                        "icon-image": "house",
                        "database-id": houseID,
                      },
                      geometry: {
                        type: "Point",
                        coordinates: houseCoordinates,
                      },
                    };
                  }),
              };
              return (
                <Source
                  id={layer.name}
                  type="geojson"
                  data={geoJSON}
                  key={layer.name}
                >
                  <Layer
                    id={layer.name}
                    type="circle"
                    paint={{
                      "circle-color": layer.color,
                      "circle-radius": {
                        'base': 1.75,
                        'stops': [
                          [12, 2],
                          [22, 180]
                        ]
                      },
                      "circle-stroke-width": 4,
                      "circle-stroke-color": layer.outline,
                    }}
                  />
                </Source>
              );
            })}
          </Map>
        </MapProvider>
      </div>
    );
  }

  addrClick(objs: any, coordinates: any) {
    const obj = objs.find((obj: any) => {
      return layers.some((layer) => layer.name === obj.source);
    });
    if (obj) {
      // If marker exists at click location, open popup
      var house = JSON.parse(obj.properties["house"]);
      this.props.onHouseClick(house);
    } else if (navigator.onLine) {
      const labelObj = objs.find((obj: any) => {
        return obj.sourceLayer === "housenum_label";
      });
      if (labelObj) {
        // Otherwise add a new house to the db
        const url =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          coordinates.lng +
          "," +
          coordinates.lat +
          ".json?access_token=" +
          MAPBOX_ACCESS_TOKEN +
          "&types=address";
        fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Network response was not ok.");
            }
          })
          .then((data) => {
            this.addAddressAndOpen(data.features[0]);
          });
      }
    }
  }

  addAddressAndOpen(feature: any) {
    callAPI("address/", "POST", {
      address: feature.place_name,
      lat: feature.center[0], // these are backwards, but that is how the API was written
      lng: feature.center[1],
      quad_id: this.props.quad,
    })
      .then((data: any) => {
        this.props.onRefreshRequest();
        this.props.onHouseClick({
          id: data.id,
          address: data.address,
          lat: data.lat.replace("L", ""),
          lng: data.lng.replace("L", ""),
          layerType: data.layerType,
          quad_id: data.quad_id,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}

export default QuadMap;
