import { Component } from "react";
import { useParams } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import HouseInfo from "./HouseInfo";
import QuadMap from "./QuadMap";
import { Quad } from "./QuadsPage";

interface QuadPageProps {
  id: number;
  theme: "light" | "dark";
}

interface QuadPageState {
  houses: House[];
  quad: Quad | null;
  quadBorder: any;
  houseSelected: House | null;
  assignedQuads: number[];
}

export interface House {
  address: string;
  lat: number;
  lng: number;
  id: number;
  layerType: string;
  quad_id: number;
}

class QuadPageWithID extends Component<QuadPageProps, QuadPageState> {
  constructor(props: QuadPageProps) {
    super(props);
    this.state = {
      houses: [],
      quad: null,
      quadBorder: null,
      houseSelected: null,
      assignedQuads: [],
    };
  }

  loadHouses() {
    callAPI("address/?quad=" + this.props.id).then((data: any) => {
      if (data && data.houses) {
        this.setState({ houses: data.houses });
      }
    });
  }

  loadQuads() {
    callAPI("quads/?id=" + this.props.id).then((data: any) => {
      if (data && data.quad) {
        this.setState({
          quad: data.quad,
          quadBorder: data.quad.polygon,
        });
      }
    });
  }

  loadAssignedQuads() {
    callAPI("quads/?assigned")
      .then((response: any) => {
        this.setState({
          assignedQuads: response.data.map((quad: Quad) => quad.quad_id),
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadHouses();
    this.loadQuads();
    this.loadAssignedQuads();
  }

  componentDidUpdate(prevProps: Readonly<QuadPageProps>): void {
    if (prevProps.id !== this.props.id) {
      this.loadHouses();
      this.loadQuads();
    }
  }

  render() {
    let overlay = null;
    if (this.state.houseSelected) {
      overlay = (
        <HouseInfo
          theme={this.props.theme}
          house={this.state.houseSelected}
          onClose={() => {
            this.setState({ houseSelected: null });
          }}
          onUpdate={() => {
            unCacheAPI("address/?quad=" + this.props.id);
            this.loadHouses();
          }}
        />
      );
    }
    return (
      <div className="h-100">
        {overlay}
        <div className="h-100" style={{ display: this.state.houseSelected ? "none" : "block" }}>
          <QuadMap
            theme={this.props.theme}
            assignedQuads={this.state.assignedQuads}
            houses={this.state.houses}
            quadBorder={this.state.quadBorder}
            onHouseClick={(clickedHouse) => {
              let house = this.state.houses.find(
                (house) => house.id === clickedHouse.id
              );
              if (!house) {
                this.setState({ houses: [...this.state.houses, clickedHouse] });
                this.setState({ houseSelected: clickedHouse });
              } else {
                this.setState({ houseSelected: house });
              }
            }}
            onRefreshRequest={() => {
              unCacheAPI("address/?quad=" + this.props.id);
              this.loadHouses();
            }}
            quad={this.props.id}
            houseSelected={this.state.houseSelected}
          />
        </div>
      </div>
    );
  }
}

export default function QuadPage({ theme }: { theme: "light" | "dark" }) {
  const { quadId } = useParams();
  return <QuadPageWithID id={parseInt(quadId || "-1")} theme={theme} />;
}
