import { Component } from "react";
import { Navigate } from "react-router-dom";

interface URIRedirectProps { }

interface URIRedirectState { }

class URIRedirect extends Component<URIRedirectProps, URIRedirectState> {
  constructor(props: URIRedirectProps) {
    super(props);
    this.state = {};
  }
  render() {
    const uri = new URLSearchParams(window.location.search).get("uri");
    if (uri && !uri.startsWith("http")) {
      return <Navigate to={uri} />;
    }
    return <Navigate to="/" />;
  }
}

export default URIRedirect;
