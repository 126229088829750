import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../quads/QuadMap.css";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import React from "react";
import { Quad } from "../quads/QuadsPage";
import mapboxgl from "mapbox-gl";
import { MAP_BOUNDS } from "../../utils/Params";

interface LocationEditorProps {
  theme: "light" | "dark";
  coords: [number, number];
  onChange: (coords: [number, number]) => void;
}

interface LocationEditorState {
  editing: boolean;
  quads: GeoJSON.Feature<GeoJSON.Geometry>[];
  newCoords: [number, number];
}

let map: any = null;
let marker: any = null;

class LocationEditor extends React.Component<LocationEditorProps, LocationEditorState> {
  constructor(props: LocationEditorProps) {
    super(props);
    this.state = {
      editing: false,
      quads: [],
      newCoords: props.coords,
    };
  }

  componentDidMount(): void {
    map = null;
    this.loadQuads();
    marker = new mapboxgl.Marker({
      draggable: false
    })
      .on('dragend', () => {
        let coords = marker.getLngLat();
        this.setState({ newCoords: [coords.lng, coords.lat] });
      });
    try {
      marker.setLngLat(this.props.coords);
    } catch (e) {
      marker.setLngLat([0, 0]);
      console.log(e);
    }
  }

  loadQuads() {
    callAPI("quads/").then((data: any) => {
      const quads: GeoJSON.Feature<GeoJSON.Geometry>[] = data.data.map((q: Quad) => {
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [q.polygon],
          },
          properties: {
            quad_id: q.quad_id,
          },
        };
      });
      this.setState({ quads });
    });
  }

  render() {
    let original_location: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: this.props.coords,
      },
      properties: {},
    };
    return (
      <div className="my-2">
        <div className="row">
          {!this.state.editing && (
            <button onClick={() => {
              this.setState({ editing: true });
              marker.setDraggable(true);
            }} className="btn btn-sm btn-outline-secondary">
              Edit Location
            </button>
          )}
          {this.state.editing && (
            <button onClick={() => {
              this.setState({ editing: false });
              marker.setDraggable(false);
              this.props.onChange(this.state.newCoords);
            }} className="btn btn-sm btn-outline-secondary">
              Save
            </button>
          )}
        </div>
        <div className="polygon-map mt-2">
          <MapProvider>
            <Map
              id="polygon-map"
              ref={(ref) => {
                if (ref && !map) {
                  map = ref.getMap();
                  marker.addTo(map);
                  if (!map._controls.some((control: any) => control.modes)) {
                    map.on("idle", () => {
                      map.resize();
                    });
                  }
                }
              }}
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              initialViewState={{
                longitude: this.props.coords[0],
                latitude: this.props.coords[1],
                zoom: 15,
              }}
              mapStyle={this.props.theme === "light" ?
                "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
                "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
              maxBounds={MAP_BOUNDS}
            >
              <Source key="original_location" id="original_location" type="geojson" data={original_location}>
                <Layer
                  id="original_location"
                  type="circle"
                  paint={{
                    "circle-radius": 5,
                    "circle-color": "#ffffff",
                  }}
                />
              </Source>
              {this.state.quads.map((quad, i) => (
                <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                  <Layer
                    id={"quad" + i}
                    type="fill"
                    paint={{
                      "fill-color": "#ff0000",
                      "fill-opacity": 0.2,
                    }}
                  />
                  <Layer
                    id={"quad-label-" + i}
                    type="symbol"
                    layout={{
                      "text-field": ["get", "quad_id"],
                      "text-size": 12,
                      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    }}
                    paint={{
                      "text-color": "#000000",
                    }}
                  />
                </Source>
              ))}
            </Map>
          </MapProvider>
        </div>
      </div >
    );
  }
}

export default LocationEditor;
