import React from "react";
import { callAPI } from "../../utils/API";
import EditDriverModal from "./EditDriverModal";

interface ManageDriversProps {
  theme: "light" | "dark";
}

interface ManageDriversState {
  drivers: Driver[];
  driverEditing: Driver | null;
}

export interface Driver {
  id: number;
  name: string;
  num_trees: number;
  phone: string;
  user_id: number;
}


class ManageDrivers extends React.Component<ManageDriversProps, ManageDriversState> {
  constructor(props: ManageDriversProps) {
    super(props);
    this.state = {
      drivers: [],
      driverEditing: null,
    };
  }

  componentDidMount() {
    this.loadDrivers();
  }

  loadDrivers() {
    callAPI("address/?action=get_drivers").then((data: any) => {
      this.setState({
        drivers: data.drivers,
      });
    });
  }

  render() {
    return (
      <div>
        <h3>Drivers</h3>
        <hr />
        <table className={"table " +
          (this.props.theme === "dark" && "text-white")}>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th># Trees Assigned</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.drivers.map((driver: Driver) => {
              return (
                <tr key={driver.id}>
                  <td>{driver.id}</td>
                  <td>{driver.name}</td>
                  <td>{driver.phone}</td>
                  <td>{driver.num_trees}</td>
                  <td>
                    <button className="btn btn-sm btn-primary" onClick={() => {
                      this.setState({
                        driverEditing: driver,
                      });
                    }}>
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.state.drivers.length === 0 && (
          <p className="text-muted">
            No drivers found.
          </p>
        )}
        <EditDriverModal
          driver={this.state.driverEditing}
          onClose={() => {
            this.setState({
              driverEditing: null,
            });
          }}
          onSave={() => {
            this.loadDrivers();
            this.setState({
              driverEditing: null,
            });
          }}
        />
      </div>
    );
  }
}

export default ManageDrivers;
