import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../quads/QuadMap.css";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React from "react";
import { MAP_BOUNDS } from "../../utils/Params";
import { PickupGroup } from "./ManagePickupGroups";

interface SelectPickupGroupProps {
  theme: "light" | "dark";
  onGroupSelect(group_id: number): void;
  center?: [number, number];
}

interface SelectPickupGroupState {
  pickup_groups: GeoJSON.Feature<GeoJSON.Geometry>[];
  selectedQuad: number | null;
}

let map: any = null;

class SelectPickupGroup extends React.Component<SelectPickupGroupProps, SelectPickupGroupState> {
  constructor(props: SelectPickupGroupProps) {
    super(props);
    this.state = {
      pickup_groups: [],
      selectedQuad: null
    };
  }

  componentDidMount(): void {
    map = null;
    this.loadGroups();
  }

  loadGroups() {
    callAPI("pickup-groups/").then((data: any) => {
      const groups: GeoJSON.Feature<GeoJSON.Geometry>[] = data.data
        .filter((group: PickupGroup) => group.name !== "Quad #0")
        .map((q: PickupGroup) => {
          const BUFFER_SIZE = 0.0004;
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [q.min_lat - BUFFER_SIZE, q.min_lng - BUFFER_SIZE],
                  [q.min_lat - BUFFER_SIZE, q.max_lng + BUFFER_SIZE],
                  [q.max_lat + BUFFER_SIZE, q.max_lng + BUFFER_SIZE],
                  [q.max_lat + BUFFER_SIZE, q.min_lng - BUFFER_SIZE],
                  [q.min_lat - BUFFER_SIZE, q.min_lng - BUFFER_SIZE],
                ],
              ],
            },
            properties: {
              id: q.id,
              name: q.name,
              is_assigned: q.is_assigned,
            },
          };
        });
      this.setState({ pickup_groups: groups });
    });
  }

  render() {
    return (
      <div className="h-100">
        <div className="map h-100">
          <MapProvider>
            <Map
              id="quads-map"
              ref={(ref) => {
                if (ref && !map) {
                  map = ref.getMap();
                  if (!map._controls.some((control: any) => control.modes)) {
                    map.on("idle", () => {
                      map.resize();
                    });
                    // on click, get quad_id of clicked quad
                    map.on("click", (e: any) => {
                      const features = map.queryRenderedFeatures(e.point);
                      for (const feature of features) {
                        if (feature.source.startsWith("quad")) {
                          this.props.onGroupSelect(feature.properties.id);
                        }
                      }
                    });
                  }
                }
              }}
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              initialViewState={{
                longitude: this.props.center ? this.props.center[0] : -122.21,
                latitude: this.props.center ? this.props.center[1] : 47.63,
                zoom: this.props.center ? 15 : 12,
              }}
              mapStyle={this.props.theme === "light" ?
                "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
                "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
              maxBounds={MAP_BOUNDS}
            >
              {this.state.pickup_groups.map((quad, i) => (
                <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                  <Layer
                    id={"quad-" + i}
                    type="fill"
                    paint={{
                      "fill-color": ["case", ["get", "is_assigned"], "#0000ff", "#00ff00"],
                      "fill-opacity": 0.2,
                      "fill-outline-color": "#000000",
                    }}
                  />
                  <Layer
                    id={"quad-label-" + i}
                    type="symbol"
                    layout={{
                      "text-field": ["get", "name"],
                      "text-size": 12,
                      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    }}
                    paint={{
                      "text-color": "#000000",
                    }}
                  />
                </Source>
              ))}
            </Map>
          </MapProvider>
        </div>
      </div >
    );
  }
}

export default SelectPickupGroup;
