import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, unCacheAPI } from "../../utils/API";
import { Tag } from "./PickupPage";
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from "@mui/material/IconButton";
import MoveTagToGroupModal from "./MoveTagToGroupModal";
import { formatter, TREE_TAG_PRICE } from "../../utils/Formatter";
import { shortenAddress } from "../quads/HouseInfo";
import { Link } from "react-router-dom";

interface TagInfoProps {
  tree: Tag;
  onClose: () => void;
  onUpdate: () => void;
  theme: "light" | "dark";
}

interface TagInfoState {
  history: any[];
  tag_state: TagState;
  moveTagModalOpen: boolean;
}

interface TagState {
  result: number;
  money: number;
}

class TagInfo extends Component<TagInfoProps, TagInfoState> {
  constructor(props: TagInfoProps) {
    super(props);
    this.state = {
      history: [],
      tag_state: {
        result: props.tree.picked_up_status,
        money: props.tree.money_later || 0,
      },
      moveTagModalOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    // add back button listener
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      this.props.onClose();
    };
  }

  componentWillUnmount() {
    window.removeEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    window.onpopstate = null;
  }

  keyDown(e: any) {
    if (e.key === "Escape") {
      this.props.onClose();
    }
  }

  saveResponse() {
    callAPI("address/", "POST", {
      action: "log_pickup",
      response_id: this.props.tree.id,
      money_today: this.state.tag_state.money || 0,
      status: this.state.tag_state.result,
    })
      .then((res) => {
        unCacheAPI("address/?house_id=" + this.props.tree.house_id);
        this.props.onClose();
        this.props.onUpdate();
      })
      .catch((err) => {
        toast.error("Error saving result", { id: "error" });
      });
  }

  moveToNewPickupGroup() {
    callAPI("pickup-groups/", "POST", {
      action: "create",
      response_id: this.props.tree.id,
    })
      .then((res) => {
        unCacheAPI("address/?house_id=" + this.props.tree.house_id);
        this.props.onClose();
        this.props.onUpdate();
      })
      .catch((err) => {
        toast.error("Error creating new pickup group", { id: "error" });
      });
  }

  getDirections() {
    // Open address in default maps app
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      this.props.tree.address.split(",")[0]
    );
  }

  render() {
    if (!this.props.tree) {
      return <div>Tree not loaded</div>;
    }
    if (!this.props.tree.address) {
      console.log(this.props.tree);
      return <div>Tree does not have an address</div>;
    }
    const owed = (this.props.tree.num_tags * TREE_TAG_PRICE) - this.props.tree.money;
    const shortAddress = shortenAddress(this.props.tree.address.split(",")[0]);
    return (
      <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
        <div className="overlay-header text-center my-3">
          <h3>
            {shortAddress.toUpperCase()}
            <IconButton color="primary" aria-label="get directions" component="label" onClick={() => this.getDirections()}>
              <DirectionsIcon />
            </IconButton>
          </h3>
        </div>
        <div className="overlay-body container">
          <Card variant="outlined" className="p-3">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_result">Status</label>
                  <select
                    className="form-select"
                    id="addr_result"
                    value={this.state.tag_state.result}
                    onChange={(e) => {
                      this.setState({
                        tag_state: {
                          ...this.state.tag_state,
                          result: parseInt(e.target.value),
                        },
                      });
                    }}
                  >
                    <option value="0">Incomplete - Need to Return</option>
                    <option value="1">Complete - Picked Up</option>
                    <option value="2">Complete - Not Found</option>
                    <option value="3">Complete - Found, Not Paid</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_money">Money Collected Today</label>
                  <input
                    type="number"
                    className="form-control"
                    id="addr_money"
                    placeholder="$0.00"
                    value={this.state.tag_state.money}
                    onChange={(e) => {
                      this.setState({
                        tag_state: {
                          ...this.state.tag_state,
                          money: parseFloat(e.target.value),
                        },
                      });
                    }}
                  />
                </div>
                {owed > 0 && this.state.tag_state.money < owed && (
                  <div className="alert alert-warning mt-2" role="alert">
                    <strong>Warning!</strong> You are collecting less than the
                    amount owed. This house has been recorded as having ordered {this.props.tree.num_tags} tag{
                      this.props.tree.num_tags === 1 ? "" : "s"
                    }, and has paid {formatter.format(this.props.tree.money)} so far. If no money is found on the tree,
                    we do not recommend that you pick it up.
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => {
                      this.saveResponse();
                    }}
                  >
                    Report result
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <hr className="my-4" />
              {localStorage.getItem("role") === "admin" && (
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.setState({ moveTagModalOpen: true });
                      }}
                    >
                      Move to other group
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.moveToNewPickupGroup();
                      }}
                    >
                      Move to new group
                    </button>
                    <Link
                      className="btn btn-outline-warning mt-2 me-2 w-xs-100"
                      to={"/houses/" +
                        this.props.tree.house_id}
                    >
                      Edit house
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Card>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Card variant="outlined" className="m-2 p-3">
                <div className="row">
                  <div className="col-12">
                    <h4>Contact Info</h4>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <h5>
                          {this.props.tree.first_name} {this.props.tree.last_name}
                        </h5>
                      </div>
                      <div className="col-12">
                        <h6>
                          {formatPhone(this.props.tree.phone)}
                        </h6>
                      </div>
                      <div className="col-12">
                        <h6>
                          {formatEmail(this.props.tree.email)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-sm-12">
              <Card variant="outlined" className="mt-2 p-3">
                <div className="row">
                  <div className="col-12">
                    <h4>Tree Info</h4>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <h6>
                          Sold by {this.props.tree.scout} on {new Date(this.props.tree.timestamp).toLocaleDateString()}
                        </h6>
                      </div>
                      <div className="col-12">
                        <h6>
                          {"🎄".repeat(this.props.tree.num_tags)}{" "}
                          {this.props.tree.num_tags} tree{
                            this.props.tree.num_tags === 1 ? "" : "s"
                          } expected
                        </h6>
                      </div>
                      <div className="col-12">
                        {owed > 0 ? (
                          <h6>
                            {formatter.format(owed)} expected for {this.props.tree.num_tags} tree{
                              this.props.tree.num_tags === 1 ? "" : "s"
                            }, not paid yet.
                          </h6>
                        ) : (
                          <h6>
                            No money expected, already paid.
                          </h6>
                        )}
                      </div>
                      <div className="col-12">
                        <p className="text-muted">Notes:</p>
                        <p className="text-muted">{this.props.tree.pickup_note}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div >
        <MoveTagToGroupModal
          tag={this.props.tree}
          open={this.state.moveTagModalOpen}
          onClose={() => {
            this.setState({ moveTagModalOpen: false });
          }}
          onUpdate={() => {
            this.props.onUpdate();
          }}
          theme={this.props.theme}
        />
      </div >
    );
  }
}

export function formatPhone(phone: string) {
  if (!phone) return "";
  phone = phone.replace(/\D/g, "");
  if (phone.length === 11 && phone[0] === "1") {
    phone = phone.slice(1);
  }
  if (phone.length === 10) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else {
    return phone;
  }
}

export function formatEmail(email: string) {
  if (!email.includes("@")) return "";
  return email.toLocaleLowerCase();
}

export default TagInfo;
