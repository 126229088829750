import { Component } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { callAPI, unCacheAPI } from "../../utils/API";
import { PickupGroup } from "./ManagePickupGroups";

interface RenameGroupModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  group: PickupGroup;
}

interface RenameGroupModalState {
  name: string;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class RenameGroupModal extends Component<
  RenameGroupModalProps,
  RenameGroupModalState
> {
  constructor(props: RenameGroupModalProps) {
    super(props);
    this.state = {
      name: props.group.name,
    };
  }

  save() {
    callAPI("pickup-groups/", "POST", {
      action: "rename",
      id: this.props.group.id,
      new_name: this.state.name,
    }).then(() => {
      unCacheAPI("hours/");
      this.props.onClose();
      this.props.onUpdate();
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addRenameGroupModalLabel">
                Rename Group
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="driver">Name</label>
                  <input
                    className="form-control"
                    id="driver"
                    value={this.state.name}
                    onChange={(e) =>
                      this.setState({ name: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  this.save();
                }}
              >
                Rename
              </button>
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default RenameGroupModal;
