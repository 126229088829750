import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React from "react";
import { Quad } from "./QuadsPage";
import { Navigate } from "react-router-dom"
import { MAP_BOUNDS } from "../../utils/Params";

interface MasterQuadListProps {
  theme: "light" | "dark";
}

interface MasterQuadListState {
  quads: GeoJSON.Feature<GeoJSON.Geometry>[];
  selectedQuad: number | null;
}

let map: any = null;

class MasterQuadList extends React.Component<MasterQuadListProps, MasterQuadListState> {
  constructor(props: MasterQuadListProps) {
    super(props);
    this.state = {
      quads: [],
      selectedQuad: null
    };
  }

  componentDidMount(): void {
    map = null;
    this.loadQuads();
  }

  loadQuads() {
    callAPI("quads/").then((data: any) => {
      const quads: GeoJSON.Feature<GeoJSON.Geometry>[] = data.data.map((q: Quad) => {
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [q.polygon],
          },
          properties: {
            quad_id: q.quad_id,
            is_assigned: q.assigned_to !== null && q.assigned_to.length > 2,
          },
        };
      });
      this.setState({ quads });
    });
  }

  onQuadClick(n: number) {
    this.setState({ selectedQuad: n });
  }

  render() {
    if (this.state.selectedQuad !== null) {
      return <Navigate to={`/quads/${this.state.selectedQuad}/edit`} />
    }
    return (
      <div className="h-100">
        <div className="map h-100">
          <MapProvider>
            <Map
              id="quads-map"
              ref={(ref) => {
                if (ref && !map) {
                  map = ref.getMap();
                  if (!map._controls.some((control: any) => control.modes)) {
                    map.on("idle", () => {
                      map.resize();
                    });
                    // on click, get quad_id of clicked quad
                    map.on("click", (e: any) => {
                      const features = map.queryRenderedFeatures(e.point);
                      for (const feature of features) {
                        if (feature.source.startsWith("quad")) {
                          this.onQuadClick(feature.properties.quad_id);
                        }
                      }
                    });
                  }
                }
              }}
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              initialViewState={{
                longitude: -122.21,
                latitude: 47.63,
                zoom: 13,
              }}
              mapStyle={this.props.theme === "light" ?
                "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
                "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
              maxBounds={MAP_BOUNDS}
            >
              {this.state.quads.map((quad, i) => (
                <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                  <Layer
                    id={"quad-" + i}
                    type="fill"
                    paint={{
                      "fill-color": ["case", ["get", "is_assigned"], "#0000ff", "#00ff00"],
                      "fill-opacity": 0.2,
                      "fill-outline-color": "#000000",
                    }}
                  />
                  <Layer
                    id={"quad-label-" + i}
                    type="symbol"
                    layout={{
                      "text-field": ["get", "quad_id"],
                      "text-size": 12,
                      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    }}
                    paint={{
                      "text-color": "#000000",
                    }}
                  />
                </Source>
              ))}
            </Map>
          </MapProvider>
        </div>
      </div >
    );
  }
}

export default MasterQuadList;
