import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React from "react";
import * as turf from "@turf/turf";
import toast from "react-hot-toast";
import { Quad } from "./QuadsPage";
import { House } from "./QuadPage";
import { MAP_BOUNDS } from "../../utils/Params";

interface DrawPolygonProps {
  theme: "light" | "dark";
  polygon: number[][];
  quad_id: number;
  onChange: (polygon: any) => void;
}

interface DrawPolygonState {
  editing: boolean;
  otherQuads: GeoJSON.Feature<GeoJSON.Geometry>[];
  houseGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> | null;
}

let map: any = null;
let draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  defaultMode: "draw_polygon",
});

function isValidPolygon(polygon: any): boolean {
  if (!polygon || polygon.length < 4) {
    return false;
  }
  for (let i = 0; i < polygon.length; i++) {
    if (polygon[i] && polygon[i].length !== 2) {
      return false;
    }
  }
  return true;
}

class DrawPolygon extends React.Component<DrawPolygonProps, DrawPolygonState> {
  constructor(props: DrawPolygonProps) {
    super(props);
    this.state = {
      editing: false,
      otherQuads: [],
      houseGeoJSON: null,
    };
  }

  updateArea(e: any) {
    const data = draw.getAll();
    if (data.features.length > 0) {
      this.props.onChange((data.features[0].geometry as any).coordinates[0]);
    } else {
      this.props.onChange([]);
      if (e.type !== "draw.delete") toast("Click the map to draw a polygon.");
    }
  }

  componentDidUpdate(prevProps: Readonly<DrawPolygonProps>): void {
    if (
      this.props.polygon !== prevProps.polygon &&
      isValidPolygon(this.props.polygon)
    ) {
      draw.deleteAll();
      let polygon = turf.polygon([this.props.polygon]);
      draw.add(polygon);
      // zoom map to polygon
      let bbox = turf.bbox(polygon);
      map.fitBounds(bbox, {
        padding: 50,
      });
    }
  }

  componentDidMount(): void {
    map = null;
    this.loadQuads();
    this.loadHouses();
  }

  loadQuads() {
    callAPI("quads/").then((data: any) => {
      const quads = data.data.filter((q: Quad) => q.quad_id !== this.props.quad_id);
      const otherQuads: GeoJSON.Feature<GeoJSON.Geometry>[] = quads.map((q: Quad) => {
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [q.polygon],
          },
          properties: {
            quad_id: q.quad_id,
          },
        };
      });
      this.setState({ otherQuads });
    });
  }

  loadHouses() {
    callAPI("address/?quad=" + this.props.quad_id).then((data: any) => {
      if (data && data.houses) {
        const houses: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
          type: "FeatureCollection",
          features: data.houses.map((house: House) => {
            const houseCoordinates = [house.lat, house.lng];
            return {
              type: "Feature",
              properties: {
                icon: "house",
                "icon-image": "house",
              },
              geometry: {
                type: "Point",
                coordinates: houseCoordinates,
              },
            };
          })
        };
        this.setState({ houseGeoJSON: houses });
      }
    });
  }

  render() {
    return (
      <div className="my-2">
        {!this.state.editing && (
          <button onClick={() => this.setState({ editing: true })} className="btn btn-outline-secondary">
            Edit Polygon
          </button>
        )}
        <div className={this.state.editing ? "polygon-map" : "d-none"}>
          <MapProvider>
            <Map
              id="polygon-map"
              ref={(ref) => {
                if (ref && !map) {
                  map = ref.getMap();
                  if (!map._controls.some((control: any) => control.modes)) {
                    map.addControl(draw);
                    map.on("draw.create", (e: any) => {
                      this.updateArea(e);
                    });
                    map.on("draw.delete", (e: any) => {
                      this.updateArea(e);
                    });
                    map.on("draw.update", (e: any) => {
                      this.updateArea(e);
                    });
                    map.on("idle", () => {
                      map.resize();
                    });
                    if (isValidPolygon(this.props.polygon)) {
                      draw.deleteAll();
                      let polygon = turf.polygon([this.props.polygon]);
                      draw.add(polygon);
                      // zoom map to polygon
                      let bbox = turf.bbox(polygon);
                      map.fitBounds(bbox, {
                        padding: 50,
                      });
                    }
                  }
                }
              }}
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              initialViewState={{
                longitude: -122.21,
                latitude: 47.63,
                zoom: 13,
              }}
              mapStyle={this.props.theme === "light" ?
                "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
                "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
              maxBounds={MAP_BOUNDS}
            >
              {this.state.otherQuads.map((quad, i) => (
                <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                  <Layer
                    id={"quad" + i}
                    type="fill"
                    paint={{
                      "fill-color": "#ff0000",
                      "fill-opacity": 0.2,
                    }}
                  />
                  <Layer
                    id={"quad-label-" + i}
                    type="symbol"
                    layout={{
                      "text-field": ["get", "quad_id"],
                      "text-size": 12,
                      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    }}
                    paint={{
                      "text-color": "#000000",
                    }}
                  />
                </Source>
              ))}
              {this.state.houseGeoJSON && (
                <Source
                  id={"houses"}
                  type="geojson"
                  data={this.state.houseGeoJSON}
                  key={"houses"}
                >
                  <Layer
                    id={"houses"}
                    type="circle"
                    paint={{
                      "circle-color": "#000000",
                      "circle-radius": {
                        'base': 1.75,
                        'stops': [
                          [12, 2],
                          [22, 180]
                        ]
                      },
                      "circle-stroke-width": 2,
                      "circle-stroke-color": "#ffffff",
                    }}
                  />
                </Source>
              )}
            </Map>
          </MapProvider>
        </div>
      </div>
    );
  }
}

export default DrawPolygon;
