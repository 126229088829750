import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import {
  formatter,
  TIMEZONE_OFFSET_HRS,
  TREE_TAG_PRICE,
} from "../../utils/Formatter";
import { House } from "./QuadPage";

interface HouseInfoProps {
  theme: "light" | "dark";
  house: House;
  onClose: () => void;
  onUpdate: () => void;
}

interface HouseInfoState {
  history: any[];
  newResponse: Response;
}

interface Response {
  logForToday: boolean;
  date: string;
  scout: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  result: number;
  money: number;
  num_tags: number;
  notes: string;
}

const responses = ["Tree Tag", "Donation", "Refused", "No Answer", "Other"];

class HouseInfo extends Component<HouseInfoProps, HouseInfoState> {
  constructor(props: HouseInfoProps) {
    super(props);
    this.state = {
      history: [],
      newResponse: {
        logForToday: true,
        date: "",
        scout: parseInt(localStorage.getItem("user_id") || "0"),
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        result: 3,
        money: 0,
        num_tags: 0,
        notes: "",
      },
    };
  }

  loadHistory() {
    callAPI("address/?house_id=" + this.props.house.id)
      .then((res) => {
        this.setState({ history: res.responses });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadHistory();
    window.addEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    // add back button listener
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      this.props.onClose();
    };
  }

  componentWillUnmount() {
    window.removeEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    window.onpopstate = null;
  }

  keyDown(e: any) {
    if (e.key === "Escape") {
      this.props.onClose();
    }
  }

  saveResponse() {
    let date: string | Date = new Date();
    let num_tags = this.state.newResponse.num_tags;
    let money = this.state.newResponse.money;
    let result = this.state.newResponse.result;
    let first_name_present = this.state.newResponse.first_name.length > 0;
    let email_present = this.state.newResponse.email.length > 0;
    let phone_present = this.state.newResponse.phone.length > 0;
    if (!this.state.newResponse.logForToday) {
      date = new Date(
        this.state.newResponse.date +
        "T" +
        new Date().toLocaleTimeString("en-US", {
          hour12: false,
          timeZone: "America/New_York",
        })
      );
    }
    date = new Date(date.getTime() - TIMEZONE_OFFSET_HRS * 60 * 60 * 1000);
    date = date.toISOString().slice(0, 19).replace("T", " ");
    if (result !== 0) num_tags = 0;
    if (result > 1) money = 0;

    // If result is donation or tree tag and money is zero, error
    if (result <= 1 && !money) {
      toast.error("Donations / tag sales can't be $0.00", { id: "error" });
      return;
    }
    // If result is tree tage and num_tags is zero, error
    if (!result && !num_tags) {
      toast.error("Can't sell 0 tags", { id: "error" });
      return;
    }

    // If result is other and notes is empty, error
    if (result === 4 && !this.state.newResponse.notes) {
      toast.error("Can't set result to 'Other' without note", { id: "error" });
      return;
    }

    // If money is less than num tags * tag_price, error
    if (result === 0 && money < num_tags * TREE_TAG_PRICE) {
      toast.error(
        "Can't sell tags for less than " +
        formatter.format(TREE_TAG_PRICE * num_tags),
        { id: "error" });
      return;
    }
    if (result === 0 && !first_name_present) {
      toast.error("Can't sell tags without a first name", { id: "error" });
      return;
    }
    if (result === 0 && (!email_present || !phone_present)) {
      toast.error("Can't sell tags without an email or phone number", { id: "error" });
      return;
    }
    const data = {
      action: "save_response",
      house_id: this.props.house.id,
      money: money,
      first_name: this.state.newResponse.first_name,
      last_name: this.state.newResponse.last_name,
      phone: this.state.newResponse.phone,
      email: this.state.newResponse.email,
      num_tags: num_tags,
      notes: this.state.newResponse.notes,
      result: result,
      date: date,
      quad_id: this.props.house.quad_id,
      user_id: this.state.newResponse.scout,
    };
    callAPI("address/", "POST", data)
      .then((res) => {
        unCacheAPI("address/?house_id=" + this.props.house.id);
        this.props.onClose();
        this.props.onUpdate();
      })
      .catch((err) => {
        toast.error("Error saving response", { id: "error" });
      });
  }

  deleteResponse(id: number) {
    if (window.confirm("Are you sure you want to delete this response?")) {
      callAPI("address/", "POST", {
        action: "delete_response",
        response_id: id,
      })
        .then((res) => {
          unCacheAPI("address/?house_id=" + this.props.house.id);
          this.loadHistory();
          this.props.onUpdate();
        })
        .catch((err) => {
          toast.error("Error deleting response", { id: "error" });
          console.log(err);
        });
    }
  }

  render() {
    if (!this.props.house) {
      return <div>House not loaded</div>;
    }
    if (!this.props.house.address) {
      console.log(this.props.house);
      return <div>House does not have an address</div>;
    }
    const shortAddress = shortenAddress(this.props.house.address.split(",")[0]);
    return (
      <div className="mb-5 mt-2 pb-5" style={{ maxWidth: "100vw", overflow: "hidden" }}>
        <div className="overlay-header text-center my-3">
          <h3>{shortAddress.toUpperCase()}</h3>
        </div>
        <div className="overlay-body container">
          <Card variant="outlined" className="p-3">
            <div className="row">
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="dateIsToday"
                    checked={this.state.newResponse.logForToday}
                    onChange={(e) => {
                      this.setState({
                        newResponse: {
                          ...this.state.newResponse,
                          logForToday: e.target.checked,
                        },
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="dateIsToday"
                  >
                    Log for today
                  </label>
                </div>
              </div>
              {!this.state.newResponse.logForToday && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group" id="addr_date_div">
                    <label htmlFor="date">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="addr_date"
                      value={this.state.newResponse.date}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            date: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_result">Result</label>
                  <select
                    className="form-select"
                    id="addr_result"
                    value={this.state.newResponse.result}
                    onChange={(e) => {
                      this.setState({
                        newResponse: {
                          ...this.state.newResponse,
                          result: parseInt(e.target.value),
                        },
                      });
                    }}
                  >
                    <option value="0">Tree Tag</option>
                    <option value="1">Donation</option>
                    <option value="2">Refused</option>
                    <option value="3">No Answer</option>
                    <option value="4">Other - See Comment</option>
                  </select>
                </div>
              </div>
              {localStorage.getItem("role") === "admin" && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group" id="user_logging_div">
                    <label htmlFor="scout">Scout</label>
                    <select
                      className="form-select"
                      id="scout"
                      value={this.state.newResponse.scout}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            scout: parseInt(e.target.value),
                          },
                        });
                      }}
                    >
                      <option value={parseInt(localStorage.getItem("user_id") || "0")}>You</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.newResponse.result <= 1 && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="addr_first_name">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addr_first_name"
                      placeholder="First Name"
                      value={this.state.newResponse.first_name}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            first_name: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.newResponse.result === 0 && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="addr_last_name">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addr_last_name"
                      placeholder="Last Name"
                      value={this.state.newResponse.last_name}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            last_name: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.newResponse.result === 0 && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="addr_email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="addr_email"
                      placeholder="Email"
                      value={this.state.newResponse.email}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            email: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.newResponse.result === 0 && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="addr_phone">Phone</label>
                    <input
                      type="phone"
                      className="form-control"
                      id="addr_phone"
                      placeholder="Phone"
                      value={this.state.newResponse.phone}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            phone: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.newResponse.result <= 1 && (
                <div className="col-md-3 col-sm-6" id="money_input">
                  <div className="form-group">
                    <label htmlFor="addr_money">Money Collected</label>
                    <input
                      type="number"
                      className="form-control"
                      id="addr_money"
                      placeholder="$0.00"
                      value={this.state.newResponse.money}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            money: parseFloat(e.target.value),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.newResponse.result === 0 ? (
                <div className="col-md-3 col-sm-6" id="tags_input">
                  <div className="form-group">
                    <label htmlFor="addr_tags">Tree Tags Sold</label>
                    <input
                      type="number"
                      className="form-control"
                      id="addr_tags"
                      placeholder="0"
                      value={this.state.newResponse.num_tags}
                      onChange={(e) => {
                        this.setState({
                          newResponse: {
                            ...this.state.newResponse,
                            num_tags: parseInt(e.target.value),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="addr_notes">Notes</label>
                  <textarea
                    className="form-control"
                    id="addr_notes"
                    value={this.state.newResponse.notes}
                    onChange={(e) => {
                      this.setState({
                        newResponse: {
                          ...this.state.newResponse,
                          notes: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2 w-xs-100"
                    onClick={() => {
                      this.saveResponse();
                    }}
                  >
                    Save response
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2 w-xs-100"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    Cancel
                  </button>
                  {localStorage.getItem("role") === "admin" && (
                    <Link
                      className="btn btn-outline-warning mt-2 me-2 w-xs-100"
                      to={"/houses/" +
                        this.props.house.id}
                    >
                      Edit house
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Card>
          <hr />
          <h2>History</h2>
          <div style={{ width: "auto", overflow: "auto" }}>
            <table className={"table " +
              (this.props.theme === "dark" && "text-white")}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Result</th>
                  <th>Money</th>
                  <th># Sold</th>
                  <th>Sold By</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.history?.map((response) => {
                  let date = new Date(response.timestamp);
                  if (isNaN(date.getTime())) {
                    date = new Date(response.timestamp.replace(" ", "T"));
                  }
                  return (
                    <tr key={"response_" + response.id}>
                      <td>{date.toLocaleString()}</td>
                      <td>{responses[response.response]}</td>
                      <td>{formatter.format(response.money)}</td>
                      <td>{response.num_tags}</td>
                      <td>{response.sold_by + (response.shopify_id ? " via Shopify" : "")}</td>
                      <td>{response.comment}</td>
                      <td>
                        {response.yours ? (
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => {
                              this.deleteResponse(response.id);
                            }}
                          >
                            Delete
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div >
    );
  }
}

export function shortenAddress(address: string) {
  // replace "Avenue" with "Ave", etc.
  const replacements = [
    ["Avenue", "Ave"],
    ["Street", "St"],
    ["Road", "Rd"],
    ["Drive", "Dr"],
    ["Court", "Ct"],
    ["Lane", "Ln"],
    ["Circle", "Cir"],
    ["Boulevard", "Blvd"],
    ["Terrace", "Ter"],
    ["Parkway", "Pkwy"],
    ["Place", "Pl"],
    ["Way", "Wy"],
    ["Highway", "Hwy"],
    ["Northwest", "NW"],
    ["Northeast", "NE"],
    ["Southwest", "SW"],
    ["Southeast", "SE"],
    ["North", "N"],
    ["South", "S"],
    ["East", "E"],
    ["West", "W"],
  ];
  let shortAddress = address;
  for (const [long, short] of replacements) {
    // replace (but must be followed by a space or end of string)
    shortAddress = shortAddress.replace(new RegExp(long + "(?= |$)", "g"), short);
  }
  return shortAddress;
}

export default HouseInfo;
