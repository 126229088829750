import React from "react";
import ManageDrivers from "./ManageDrivers";
import ManagePickupGroups from "./ManagePickupGroups";

interface ManagePickupProps {
  theme: "light" | "dark";
}

interface ManagePickupState { }


class ManagePickup extends React.Component<ManagePickupProps, ManagePickupState> {
  constructor(props: ManagePickupProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container mt-2">
        <ManageDrivers theme={this.props.theme} />
        <ManagePickupGroups theme={this.props.theme} />
      </div>
    );
  }
}

export default ManagePickup;
