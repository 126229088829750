import React from "react";
import { toast } from "react-hot-toast";
import { callAPI } from "../../utils/API";
import AssignGroupModal from "./AssignGroupModal";

interface ManagePickupGroupsProps {
  theme: string;
}

interface ManagePickupGroupsState {
  pickup_groups: PickupGroup[];
  groupSelectedForAssignment: PickupGroup | null;
}

export interface PickupGroup {
  id: number;
  name: string;
  assigned_name: string;
  assigned_to: number;
  num_collected: number;
  num_left: number;
  num_skipped: number;
  avg_lat: number;
  avg_lng: number;
  radiusInKm: number;
  max_lat: number;
  max_lng: number;
  min_lat: number;
  min_lng: number;
  is_assigned: boolean;
  yours: boolean;
}


class ManagePickupGroups extends React.Component<ManagePickupGroupsProps, ManagePickupGroupsState> {
  constructor(props: ManagePickupGroupsProps) {
    super(props);
    this.state = {
      pickup_groups: [],
      groupSelectedForAssignment: null,
    };
  }

  componentDidMount() {
    this.loadPickupGroups();
  }

  loadPickupGroups() {
    callAPI("pickup-groups/").then((data: any) => {
      this.setState({
        pickup_groups: data.data,
      });
    });
  }

  generatePickupGroups() {
    if (window.confirm(
      "Are you sure you would like to automatically re-generate pickup groups? This will remove all old assignments."
    )) {
      const res = callAPI("pickup-groups/", "POST", {
        action: "generate"
      });
      res
        .then((data: any) => {
          this.loadPickupGroups();
        });
      toast.promise(res, {
        loading: "Generating pickup groups...",
        success: "Pickup groups generated.",
        error: "Failed to generate pickup groups.",
      });
    }
  }

  printPickupGroups() {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php",
        "_blank"
      );
    }
  }

  printPickupGroup(id: number) {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php?id=" + id,
        "_blank"
      );
    }
  }

  render() {
    return (
      <div>
        <h3>Pickup Groups</h3>
        <button
          className="btn btn-outline-primary me-2"
          onClick={() => this.generatePickupGroups()}
        >Generate</button>
        <button
          className="btn btn-outline-primary me-2"
          onClick={() => this.printPickupGroups()}
        >Print</button>
        <table className={"table " +
          (this.props.theme === "dark" && "text-white")}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Driver</th>
              <th># Collected</th>
              <th># Skipped</th>
              <th># Left</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.pickup_groups.map((pickup_group: PickupGroup) => {
              return (
                <tr key={pickup_group.id}>
                  <td>{pickup_group.name}</td>
                  <td>{pickup_group.assigned_name}</td>
                  <td>{pickup_group.num_collected}</td>
                  <td>{pickup_group.num_skipped}</td>
                  <td>{pickup_group.num_left}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary m-1"
                      onClick={() => this.setState({
                        groupSelectedForAssignment: pickup_group
                      })}
                    >Assign</button>
                    <button
                      className="btn btn-sm btn-primary m-1"
                      onClick={() => {
                        this.printPickupGroup(pickup_group.id);
                      }}
                    >Print</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.state.pickup_groups.length === 0 && (
          <p className="text-muted">
            No pickup groups found.
          </p>
        )}
        {this.state.groupSelectedForAssignment && (
          <AssignGroupModal
            open={this.state.groupSelectedForAssignment !== null}
            group={this.state.groupSelectedForAssignment}
            onClose={() => this.setState({
              groupSelectedForAssignment: null
            })}
            onUpdate={() => {
              this.loadPickupGroups();
            }}
          />
        )}
      </div>
    );
  }
}

export default ManagePickupGroups;
