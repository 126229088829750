import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import { PickupGroup } from "../pickup/ManagePickupGroups";
import PickupGroupCard from "./PickupGroupCard";

interface DriverDashboardProps { }

interface DriverDashboardState {
  stats: TreeTagStats | null;
  assigned_groups: PickupGroup[];
}

interface TreeTagStats {
  trees_assigned: number;
  trees_picked_up: number;
}

class DriverDashboard extends Component<DriverDashboardProps, DriverDashboardState> {
  constructor(props: DriverDashboardProps) {
    super(props);
    this.state = {
      stats: null,
      assigned_groups: [],
    };
  }

  loadStats() {
    callAPI("pickup/stats")
      .then((res: any) => {
        this.setState({ stats: res });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  loadGroups() {
    callAPI("pickup-groups/?assigned")
      .then((response: any) => {
        this.setState({ assigned_groups: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadStats();
    this.loadGroups();
  }

  render() {
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 col-sm-12 row">
                <div className="col-lg-6 col-sm-12">
                  <h2>
                    Your Stats{" "}
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.loadStats();
                      }}
                    >
                      Refresh
                    </button>
                  </h2>
                  <h4>
                    Trees Assigned:{" "}
                    {this.state.stats?.trees_assigned}
                  </h4>

                  <h4>Trees Picked Up: {this.state.stats?.trees_picked_up}</h4>
                </div>
              </div>
            </div>
            <Card variant="outlined" className="mt-2 p-3">
              <Link
                to="/list"
                className="btn btn-outline-primary me-2 mt-2"
              >
                Enter Tags
              </Link>
              <button
                className="btn btn-outline-secondary me-2 mt-2"
                onClick={() => {
                  this.loadGroups();
                }}
              >
                Refresh Groups
              </button>
              <hr />
              <h3>Your Pickup Groups</h3>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {this.state.assigned_groups.filter((group) => {
                  return group.yours;
                }).map((group) => (
                  <PickupGroupCard key={group.id} group={group} />
                ))}
                {this.state.assigned_groups.filter((group) => {
                  return group.yours;
                }).length === 0 && (
                    <p className="text-muted">
                      You are not assigned to any groups. Head to the map to claim a group.
                    </p>
                  )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  setGoal() {
    let ammount = prompt("Enter your goal in dollars");
    if (ammount && parseFloat(ammount)) {
      callAPI("your-stats/", "POST", { goal: parseFloat(ammount) }).then(
        (data) => {
          unCacheAPI("your-stats/");
          this.loadStats();
        }
      );
    } else {
      toast.error("Please enter a valid number", { id: "error" });
    }
  }
}

export default DriverDashboard;