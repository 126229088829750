import { Component } from "react";
import { Navigate } from "react-router-dom";

interface InstallInstructionsProps { }

interface InstallInstructionsState { }

class InstallInstructions extends Component<InstallInstructionsProps, InstallInstructionsState> {
  constructor(props: InstallInstructionsProps) {
    super(props);
    this.state = {};
  }
  render() {
    const isPWA = window.matchMedia("(display-mode: standalone)").matches;
    if (isPWA) {
      return <Navigate to="/" />;
    }
    const isMobile = window.matchMedia("(max-width: 600px)").matches;
    const isIPhone = navigator.userAgent.match(/iPhone/i);
    const isIPad = navigator.userAgent.match(/iPad/i);
    const isIOS = isIPhone || isIPad;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = navigator.userAgent.match(/Chrome/i);
    return <div className={"container p-5" + isMobile ? "text-center" : ""}>
      {isIOS ? (
        <div className="mx-auto mt-3" style={{ maxWidth: "200px" }}>
          <img src="/assets/img/install/install-ios.png" className="img-fluid" alt="Install on iOS" />
        </div>
      ) : (
        <div className="mx-auto mt-3" style={{ maxWidth: "200px" }}>
          <img src="/assets/img/install/install-android.png" className="img-fluid" alt="Install on iOS" />
        </div>
      )}
      <h1 className={"fw-normal my-2"}>Install as App</h1>
      <div className="px-5">
        <p className="text-muted">Install this site as an app for the best experience.</p>
      </div>
      {isSafari && (
        <div className="px-5">
          <p className="text-muted mb-0"><strong>It looks like you're on Safari.</strong></p>
          <p className="text-muted mb-0">On iOS, you can install this app by tapping the share button in Safari and then tapping "Add to Home Screen".</p>
        </div>
      )}
      {!isSafari && isIOS && (
        <div className="px-5">
          <div className="alert alert-danger" role="alert">
            <p className="text-muted mb-0">It looks like you're on Chrome on iOS. <strong>Unfortunately, Chrome on iOS doesn't support installing web apps.</strong></p>
            <p className="text-muted mb-0">You can install this app if you use Safari instead.</p>
          </div>
        </div>
      )}
      {isChrome && !isIOS && (
        <div className="px-5">
          <p className="text-muted mb-0">You can install this app by tapping your profile picture in the top right of this page and then choosing "Install App".</p>
        </div>
      )}
      {isIOS && (
        <div className="mt-3 pt-3 bg-dark">
          <video className="w-100" autoPlay loop muted playsInline>
            <source src="/assets/img/install/install-ios.webm" type="video/webm" />
          </video>
        </div>
      )}
      <hr />
      <div className="px-5 mb-5">
        <p className="text-muted mb-0 mt-2">If you've already installed this app, open it from your home screen.</p>
      </div>
    </div >;
  }
}

export default InstallInstructions;
