const DOMAIN = "https://troop600.com";
export function callAPI(
  endpoint: string,
  method = "GET",
  body: any = undefined
): Promise<any> {
  if (!navigator.onLine) {
    return new Promise((resolve, reject) => {
      if (method === "GET") {
        getFromLocalStorage(endpoint).then((data: any) => {
          resolve(data);
        });
      } else {
        reject("You are offline, please try again later.");
      }
    });
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    headers.append("Authorization", `Bearer ${jwt}`);
  }
  if (body instanceof FormData) {
    headers.delete("Content-Type");
  }
  let tagsURL = endpoint.charAt(0) === "/" ? "" : "/api/v1/tree-tags/";
  return fetch(DOMAIN + tagsURL + endpoint, {
    method: method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
    mode: "cors",
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      if (data.success === false) {
        if (data.message === "Access denied." && navigator.onLine) {
          localStorage.removeItem("jwt");
          // Have the user log in again
          window.location.reload();
        }
        throw new Error(data.message);
      }
      return data;
    })
    .then((data) => {
      saveInLocalStorage(endpoint, data);
      return data;
    });
}

export function unCacheAPI(endpoint: string) {
  if (navigator.onLine) {
    localStorage.removeItem(endpoint);
  }
}

function saveInLocalStorage(endpoint: string, data: any) {
  localStorage.setItem(
    endpoint,
    JSON.stringify({ data, timestamp: Date.now() })
  );
}

function getFromLocalStorage(endpoint: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const data = localStorage.getItem(endpoint);
    if (data) {
      let parsedData = JSON.parse(data);
      if (parsedData && parsedData.data) {
        resolve(parsedData.data);
      }
      reject("No data found in local storage.");
    }
    reject("No data found in local storage.");
  });
}

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoia2FlZGVuYiIsImEiOiJja2FyMmRyeHcwZ3dmMnFwbGhuZTc2MXgyIn0.vmW7QzXVTA1D879Fs3e0xA";
