import Card from "@mui/material/Card";
import { Component } from "react";
import toast, { CheckmarkIcon } from "react-hot-toast";
import { callAPI, MAPBOX_ACCESS_TOKEN, unCacheAPI } from "../../utils/API";
import { shortenAddress } from "../quads/HouseInfo";
import AssignGroupModal from "./AssignGroupModal";
import CombineGroupModal from "./CombinePickupGroupModal";
import { PickupGroup } from "./ManagePickupGroups";
import { Tag } from "./PickupPage";
import RenameGroupModal from "./RenameGroupModal";
import StaticMap from "./StaticMap";
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from "@mui/material/IconButton";

interface GroupInfoProps {
  group: PickupGroup;
  onClose: () => void;
  onUpdate: () => void;
  theme: "light" | "dark";
}

interface GroupInfoState {
  tags: Tag[];
  assignDriverModalOpen: boolean;
  renameGroupModalOpen: boolean;
  combineGroupModalOpen: boolean;
}

class GroupInfo extends Component<GroupInfoProps, GroupInfoState> {
  constructor(props: GroupInfoProps) {
    super(props);
    this.state = {
      tags: [],
      assignDriverModalOpen: false,
      renameGroupModalOpen: false,
      combineGroupModalOpen: false,
    };
  }

  componentDidMount() {
    this.loadTags();
    window.addEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    // add back button listener
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      this.props.onClose();
    };
  }

  componentWillUnmount() {
    window.removeEventListener(
      "keydown",
      (e) => {
        this.keyDown(e);
      },
      false
    );
    window.onpopstate = null;
  }

  keyDown(e: any) {
    if (e.key === "Escape") {
      this.props.onClose();
    }
  }

  loadTags() {
    callAPI("address/?type=pickup&group_id=" + this.props.group.id)
      .then((res) => {
        this.setState({ tags: res.tags });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.props.group) {
      return <div>Group not loaded</div>;
    }
    return (
      <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
        <div className="overlay-header text-center my-3">
          <h3>{this.props.group.name}</h3>
        </div>
        <div className="overlay-body container">
          <Card variant="outlined" className="p-3">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  {!this.props.group.is_assigned && (
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.claimPickupGroup();
                      }}
                    >
                      Claim
                    </button>
                  )}
                  {this.props.group.yours && (
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.unclaimPickupGroup();
                      }}
                    >
                      Unclaim
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => {
                      this.printPickupGroup();
                    }}
                  >
                    Print
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
              <hr className="my-4" />
              <div className="col-12">
                <StaticMap url={generateStaticMapURL(this.state.tags)} />
                <table className={"table " +
                  (this.props.theme === "dark" && "text-white")}>                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Address</th>
                      <th scope="col"># Tags</th>
                      <th scope="col">Name</th>
                      <th scope="col">Paid?</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tags.map((tag, i) => {
                      const char = i < 26 ? String.fromCharCode(65 + i) : i;
                      return (
                        <tr key={tag.id}>
                          <td>{char}</td>
                          <td>
                            {shortenAddress(tag.address.split(",")[0])}
                            {tag.picked_up_status === 0 ? (
                              <IconButton color="primary" aria-label="get directions" component="label" size="small" onClick={() => this.getDirections(tag)}>
                                <DirectionsIcon />
                              </IconButton>
                            ) : (
                              <IconButton disabled color="primary" aria-label="complete" component="label" size="small">
                                <CheckmarkIcon />
                              </IconButton>
                            )}
                          </td>
                          <td>{tag.num_tags}</td>
                          <td>{tag.first_name + " " + tag.last_name}</td>
                          <td>{tag.paid ? "Yes" : "No"}</td>
                          <td>{tag.pickup_note}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <hr className="my-4" />
              {localStorage.getItem("role") === "admin" && (
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.setState({ assignDriverModalOpen: true });
                      }}
                    >
                      Reassign
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.setState({ renameGroupModalOpen: true });
                      }}
                    >
                      Rename
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.setState({ combineGroupModalOpen: true });
                      }}
                    >
                      Combine with other group
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-2 me-2"
                      onClick={() => {
                        this.deleteGroup();
                      }}
                    >
                      Delete group
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
        <AssignGroupModal
          group={this.props.group}
          open={this.state.assignDriverModalOpen}
          onClose={() => { this.setState({ assignDriverModalOpen: false }); }}
          onUpdate={() => { this.setState({ assignDriverModalOpen: false }); this.props.onUpdate(); }}
        />
        <RenameGroupModal
          group={this.props.group}
          open={this.state.renameGroupModalOpen}
          onClose={() => { this.setState({ renameGroupModalOpen: false }); }}
          onUpdate={() => { this.setState({ renameGroupModalOpen: false }); this.props.onUpdate(); }}
        />
        <CombineGroupModal
          group={this.props.group}
          open={this.state.combineGroupModalOpen}
          onClose={() => { this.setState({ combineGroupModalOpen: false }); }}
          onUpdate={() => {
            this.setState({ combineGroupModalOpen: false });
            this.props.onUpdate();
            this.props.onClose();
          }}
          theme={this.props.theme}
        />
      </div >
    );
  }

  getDirections(tag: Tag) {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      tag.address.split(",")[0]
    );
  }

  printPickupGroup() {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php?id=" + this.props.group.id,
        "_blank"
      );
    }
  }

  deleteGroup() {
    if (window.confirm("Are you sure you want to delete this group? This will cause any trees in the group to be unassigned.")) {
      callAPI("pickup-groups/", "POST", {
        action: "delete",
        id: this.props.group.id,
      }).then(() => {
        unCacheAPI("pickup-groups/");
        this.props.onUpdate();
        this.props.onClose();
      });
    }
  }

  claimPickupGroup() {
    callAPI("pickup-groups/", "POST", {
      action: "claim",
      id: this.props.group.id,
    }).then(() => {
      unCacheAPI("pickup-groups/");
      this.props.onUpdate();
      this.props.onClose();
    })
      .catch((err) => {
        toast.error("Error claiming pickup group", { id: "error" });
      });
  }

  unclaimPickupGroup() {
    callAPI("pickup-groups/", "POST", {
      action: "unclaim",
      id: this.props.group.id,
    }).then(() => {
      unCacheAPI("pickup-groups/");
      this.props.onUpdate();
      this.props.onClose();
    })
      .catch((err) => {
        toast.error("Error unclaiming pickup group", { id: "error" });
      });
  }
}

function generateStaticMapURL(tags: any[]) {
  let points = [];
  // Add a row for each tag
  for (let i = 0; i < tags.length; i++) {
    const char = (i < 26) ? String.fromCharCode(97 + i) : "?"; // A-Z, then ?
    // Add GeoJSON point to points array
    points.push({
      lat: parseFloat(tags[i].lat),
      lng: parseFloat(tags[i].lng),
      color: tags[i].paid ? "000" : "666",
      char,
    });
  }
  let overlay = "";
  for (let i = 0; i < points.length; i++) {
    overlay +=
      "pin-s-" +
      points[i].char +
      "+" +
      points[i].color +
      "(" +
      points[i].lat +
      "," +
      points[i].lng +
      "),";
  }
  overlay = overlay.substring(0, overlay.length - 1);
  return "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/" +
    overlay + "/auto/1200x800?access_token=" + MAPBOX_ACCESS_TOKEN;
}

export default GroupInfo;
