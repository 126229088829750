import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Component } from "react";
import toast from "react-hot-toast";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import EfficiencyPage from "../efficiency/EfficiencyPage";
import HouseEditor from "../houses/HouseEditor";
import HouseSearch from "../houses/HouseSearch";
import Leaderboard from "../leaderboard/Leaderboard";
import AllTags from "../list/AllTags";
import TagList from "../list/TagList";
import ManualEntries from "../manual/ManualEntries";
import ManagePickup from "../pickup/ManagePickup";
import PickupPage from "../pickup/PickupPage";
import QrCodePage from "../qr-code/QRCodePage";
import MasterQuadList from "../quads/MasterQuadList";
import QuadEditor from "../quads/QuadEditor";
import Quad from "../quads/QuadPage";
import Quads from "../quads/QuadsPage";
import ScoutDashboard from "../scouts/ScoutDashboard";
import ScoutStats from "../scouts/ScoutStats";
import InstallInstructions from "../settings/InstallInstructions";
import Settings from "../settings/Settings";
import NavBar from "./NavBar";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import URIRedirect from "../settings/URIRedirect";
import MoveResponses from "../settings/MoveResponses";
import ManageSales from "../manage/Manage";
import DriverDashboard from "../dashboard/DriverDashboard";

interface MainPageProps {
  role: string;
  newVersionAvailable: boolean;
  onSWUpdateRequest: () => void;
  theme: "light" | "dark";
  onThemeUpdate: (theme: "light" | "dark") => void;
}

interface MainPageState {
  bottomNavPage: number;
  isDriverUIEnabled: boolean;
}

class MainPage extends Component<MainPageProps, MainPageState> {
  constructor(props: MainPageProps) {
    super(props);
    this.state = {
      bottomNavPage: getPathIndex(window.location.pathname, localStorage.getItem("driver-ui-enabled") === "true"),
      isDriverUIEnabled: localStorage.getItem("driver-ui-enabled") === "true",
    };
  }

  componentDidMount(): void {
    if (this.props.newVersionAvailable) {
      this.showUpdateToast();
    }
    if (
      (this.props.role === "admin" || this.props.role === "driver") &&
      !this.state.isDriverUIEnabled &&
      isPickupDay()
    ) {
      this.showDriverUISuggestionToast();
    }
  }

  componentDidUpdate(prevProps: Readonly<MainPageProps>): void {
    if (this.props.newVersionAvailable && !prevProps.newVersionAvailable) {
      this.showUpdateToast();
    }
    if (
      this.props.role !== prevProps.role &&
      (this.props.role === "admin" || this.props.role === "driver") &&
      !this.state.isDriverUIEnabled &&
      isPickupDay()
    ) {
      this.showDriverUISuggestionToast();
    }
  }

  showUpdateToast() {
    toast(
      (t) => (
        <span>
          New version available.{" "}
          <button className="btn btn-secondary" onClick={() => this.props.onSWUpdateRequest()}>Update</button>
        </span>
      ),
      {
        id: "update-toast",
        duration: Infinity,
        icon: <i className="bi bi-cloud-arrow-down-fill"></i>,
      }
    );
  }

  showDriverUISuggestionToast() {
    toast(
      (t) => (
        <span>
          Enable driver UI?{" "}
          <button className="btn btn-secondary me-1" onClick={() => {
            this.setState({ isDriverUIEnabled: true });
            localStorage.setItem("driver-ui-enabled", "true");
            toast.dismiss(t.id);
          }}>Enable</button>
          <button className={"btn btn-sm " + (this.props.theme === "light" ? "text-light" : "")} onClick={() => toast.dismiss(t.id)}>&times;</button>
        </span>
      ),
      {
        id: "driver-ui-toast",
        duration: Infinity,
        icon: <i className="bi bi-truck"></i>,
      }
    );
  }

  render() {
    return (
      <BrowserRouter>
        <NavBar role={this.props.role} theme={this.props.theme} />
        <div style={{ height: "86vh", overflow: "auto" }}>
          <Routes>
            {this.state.isDriverUIEnabled ? (
              <Route path="/" element={<DriverDashboard />} />
            ) : (
              <Route path="/" element={<Dashboard />} />
            )}
            <Route path="/leaderboard" element={<Leaderboard theme={this.props.theme} />} />
            <Route path="/settings"
              element={
                <Settings
                  theme={this.props.theme}
                  onThemeUpdate={this.props.onThemeUpdate}
                  driverUIEnabled={this.state.isDriverUIEnabled}
                  onDriverUIEnabledChange={
                    (enabled: boolean) => {
                      this.setState({
                        isDriverUIEnabled: enabled,
                        bottomNavPage: getPathIndex(window.location.pathname, enabled)
                      });
                      localStorage.setItem("driver-ui-enabled", enabled.toString());
                    }
                  }
                />
              }
            />
            <Route path="/settings/move-responses" element={<MoveResponses />} />
            <Route path="/install" element={<InstallInstructions />} />
            <Route path="/protocol" element={<URIRedirect />} />
            <Route path="/efficiency" element={<EfficiencyPage />} />
            <Route path="/list" element={<TagList theme={this.props.theme} />} />
            <Route path="/manage/list" element={<AllTags theme={this.props.theme} />} />
            <Route path="/manual" element={<ManualEntries />} />
            <Route path="/manage/quads" element={<Quads />} />
            <Route path="/manage" element={<ManageSales />} />
            <Route path="/manage/quads/map" element={<MasterQuadList theme={this.props.theme} />} />
            <Route path="/map/sales/:quadId" element={<Quad theme={this.props.theme} />} />
            <Route path="/map/sales" element={<Quad theme={this.props.theme} />} />
            <Route path="/manage/pickup" element={<ManagePickup theme={this.props.theme} />} />
            <Route path="/map/pickup/:quadId" element={<PickupPage theme={this.props.theme} />} />
            <Route path="/map/pickup" element={<PickupPage theme={this.props.theme} />} />
            <Route
              path="/quads/:quadId/edit"
              element={<QuadEditor theme={this.props.theme} />}
            />
            <Route path="/qr-code" element={<QrCodePage />} />
            <Route path="/scouts" element={<ScoutStats />} />
            <Route path="/scouts/:id" element={<ScoutDashboard />} />
            <Route path="/houses" element={<HouseSearch />} />
            <Route path="/houses/:id" element={<HouseEditor theme={this.props.theme} />} />
          </Routes>
        </div>
        {!this.state.isDriverUIEnabled ? (
          <BottomNavigation
            showLabels
            value={this.state.bottomNavPage}
            onChange={(event, newValue) => {
              this.setState({ bottomNavPage: newValue });
            }}
            className="fixed-bottom"
          >
            <BottomNavigationAction component={Link} to="/" label="Dashboard" icon={<i className="bi bi-house-door-fill"></i>} />
            <BottomNavigationAction component={Link} to="/leaderboard" label="Leaderboard" icon={<i className="bi bi-award-fill"></i>} />
            <BottomNavigationAction component={Link} to={"/map/sales/" + (localStorage.getItem("quad") || "")} label="Map" icon={<i className="bi bi-map"></i>} />
            <BottomNavigationAction component={Link} to="/qr-code" label="QR Code" icon={<i className="bi bi-qr-code-scan"></i>} />
          </BottomNavigation>
        ) : (
          <BottomNavigation
            showLabels
            value={this.state.bottomNavPage}
            onChange={(event, newValue) => {
              this.setState({ bottomNavPage: newValue });
            }}
            className="fixed-bottom"
          >
            <BottomNavigationAction component={Link} to="/" label="Dashboard" icon={<i className="bi bi-house-door-fill"></i>} />
            <BottomNavigationAction component={Link} to="/map/pickup" label="Map" icon={<i className="bi bi-map"></i>} />
            <BottomNavigationAction component={Link} to="/settings" label="Settings" icon={<i className="bi bi-gear-fill"></i>} />
          </BottomNavigation>
        )}
        <LocationListener onLocationChange={(path) => {
          const bottomNavPage = getPathIndex(path, this.state.isDriverUIEnabled);
          if (bottomNavPage !== this.state.bottomNavPage) {
            this.setState({ bottomNavPage });
          }
        }} />
      </BrowserRouter>
    );
  }
}

interface LocationListenerProps {
  onLocationChange: (location: string) => void;
}

function LocationListener(props: LocationListenerProps) {
  const location = useLocation();
  useEffect(() => {
    props.onLocationChange(location.pathname);
  }, [location, props]);
  return null;
}

function getPathIndex(path: string, isDriverUIEnabled: boolean): number {
  if (!isDriverUIEnabled) {
    if (path.startsWith("/map/sales")) {
      return 2;
    }
    switch (path) {
      case "/":
        return 0;
      case "/leaderboard":
        return 1;
      case "/qr-code":
        return 3;
      default:
        return -1;
    }
  } else {
    if (path.startsWith("/map/pickup")) {
      return 1;
    }
    switch (path) {
      case "/":
        return 0;
      case "/settings":
        return 2;
      default:
        return -1;
    }
  }
}

function isPickupDay() {
  return new Date().getDay() === 6 &&
    new Date().getMonth() === 0 &&
    new Date().getDate() < 9 &&
    new Date().getDate() > 1;
}

export default MainPage;
