import Map, {
  GeolocateControl,
  Source,
  Layer,
  MapProvider,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { callAPI, MAPBOX_ACCESS_TOKEN } from "../../utils/API";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React from "react";
import toast from "react-hot-toast";
import { Tag } from "./PickupPage";
import "../quads/QuadMap.css";
import { MAP_BOUNDS } from "../../utils/Params";
import Card from "@mui/material/Card";
import { PickupGroup } from "./ManagePickupGroups";

// fix transpilation
(mapboxgl as any).workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

const LAYERS = [
  { name: "tag_no_pickup_group", color: "#ff00ee", outline: "#ff0000", filter: (tree: Tag) => { return tree.pickup_group === null; } },
  { name: "tag_yours_paid_completed", color: "#059c31", outline: "#000000", filter: (tree: Tag) => { return tree.pickup_group !== null && tree.paid && tree.yours && isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_paid_todo", color: "#059c31", outline: "#ffff00", filter: (tree: Tag) => { return tree.pickup_group !== null && tree.paid && tree.yours && !isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_unpaid_completed", color: "#ffc000", outline: "#000000", filter: (tree: Tag) => { return tree.pickup_group !== null && !tree.paid && tree.yours && isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_unpaid_todo", color: "#ffc000", outline: "#ffff00", filter: (tree: Tag) => { return tree.pickup_group !== null && !tree.paid && tree.yours && !isCompleted(tree.picked_up_status); } },
  { name: "tag_unclaimed_completed", color: "#ff0000", outline: "#000000", filter: (tree: Tag) => { return tree.pickup_group !== null && tree.driver_assigned === null && isCompleted(tree.picked_up_status); } },
  { name: "tag_unclaimed_todo", color: "#ff0000", outline: "#ffff00", filter: (tree: Tag) => { return tree.pickup_group !== null && tree.driver_assigned === null && !isCompleted(tree.picked_up_status); } },
  { name: "tag_other_completed", color: "#000000", outline: "#000000", filter: (tree: Tag) => { return tree.pickup_group !== null && !tree.yours && tree.driver_assigned !== null && isCompleted(tree.picked_up_status); } },
  { name: "tag_other_todo", color: "#000000", outline: "#ffff00", filter: (tree: Tag) => { return tree.pickup_group !== null && !tree.yours && tree.driver_assigned !== null && !isCompleted(tree.picked_up_status); } },
];

interface PickupMapProps {
  theme: "light" | "dark";
  trees: Tag[];
  groups: PickupGroup[];
  onTreeClick: (tree: Tag) => void;
  onRefreshRequest: () => void;
  treeSelected?: Tag | null;
  groupSelected?: PickupGroup | null;
  onGroupClick: (group: PickupGroup) => void;
}

interface PickupMapState {
  legend: boolean;
  onlyYourTrees: boolean;
  driverLocations: DriverLocation[];
  locationUpdateInterval: NodeJS.Timeout | null;
  locationEnabled: boolean;
}

interface DriverLocation {
  id: number;
  user_id: number;
  username: string;
  name: string;
  lat: number;
  lng: number;
  timestamp: string;
}

let map: any = null;

class PickupMap extends React.Component<PickupMapProps, PickupMapState> {
  constructor(props: PickupMapProps) {
    super(props);
    this.state = {
      legend: !localStorage.getItem("legend"),
      onlyYourTrees: false,
      driverLocations: [],
      locationUpdateInterval: null,
      locationEnabled: true,
    };
  }

  componentDidUpdate(prevProps: PickupMapProps): void {
    if (map && (this.props.treeSelected !== prevProps.treeSelected || this.props.groupSelected !== prevProps.groupSelected)) {
      map.resize();
    }
  }

  componentDidMount(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(() => { }, (err) => {
        if (err && err.code === err.PERMISSION_DENIED) {
          this.setState({ locationEnabled: false });
        }
      });
    }
    this.loadDriverLocations();
    if (window.location.protocol === "https:") {
      // Only use interval on production deployment
      this.setState({
        locationUpdateInterval: setInterval(() => {
          this.logLocation();
          this.loadDriverLocations();
        }, 10000),
      });
    }
  }

  componentWillUnmount(): void {
    if (this.state.locationUpdateInterval) {
      clearInterval(this.state.locationUpdateInterval);
    }
  }

  loadDriverLocations() {
    callAPI("pickup-groups/?type=get_live_locations")
      .then((res) => {
        this.setState({ driverLocations: res.drivers });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  logLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        callAPI("pickup-groups/", "POST", {
          action: "log_location",
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      });
    }
  }

  render() {
    if (!this.state.locationEnabled) {
      return (
        <div className="map p-5">
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Location services disabled</h4>
            <p>
              Location services are required to use the map. Please enable location services in your browser settings and refresh the page.
            </p>
            <button className="btn btn-warning" onClick={() => this.setState({ locationEnabled: true })}>
              Continue Anyways
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="map">
        <MapProvider>
          <nav id="menu">
            <button
              className="btn btn-light mt-3 mt-md-2 me-2 float-end"
              onClick={() => {
                this.props.onRefreshRequest();
                this.loadDriverLocations();
                toast.success("Refreshed map data");
              }}
              title="Refresh"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
            <button
              className="btn btn-light mt-3 mt-md-2 me-2 float-end"
              onClick={() => {
                if (this.state.onlyYourTrees) {
                  toast("Showing all trees", { icon: "🎄", id: "show-all" });
                } else {
                  toast("Showing only your trees", { icon: "🎄", id: "show-all" });
                }
                this.setState({ onlyYourTrees: !this.state.onlyYourTrees });
              }}
              title="Show only your trees"
            >
              <i className="bi bi-person"></i>
            </button>
            {!this.state.legend && (
              <button
                className="btn btn-light mt-3 mt-md-2 me-2 float-end"
                onClick={() => {
                  this.setState({ legend: true });
                  localStorage.removeItem("legend");
                }}
                title="Show legend"
              >
                <i className="bi bi-list-ul"></i>
              </button>
            )}
          </nav>
          {this.state.legend && (
            <Card className="legend">
              <div
                className="legend-header-close"
                onClick={() => {
                  localStorage.setItem("legend", "false");
                  this.setState({ legend: false });
                }}
              >
                <span> &times; </span>
              </div>
              <h4>Legend</h4>
              <div>
                <span
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    border: "1px solid rgb(0, 0, 0)",
                  }}
                ></span>
                Completed
              </div>
              <div>
                <span
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    border: "1px solid rgb(255, 255, 0)",
                  }}
                ></span>
                Not Completed
              </div>
              <hr className="my-1" />
              <div>
                <span
                  style={{
                    backgroundColor: "#059c31",
                    border: "1px solid rgb(5, 156, 49)",
                  }}
                ></span>
                Your Tree (Paid)
              </div>
              <div>
                <span
                  style={{
                    backgroundColor: "#ffc000",
                    border: "1px solid rgb(255, 192, 0)",
                  }}
                ></span>
                Your Tree (Not Paid)
              </div>
              {!this.state.onlyYourTrees && (
                <div>
                  <span
                    style={{
                      backgroundColor: "rgb(255, 0, 0)",
                      border: "1px solid rgb(255, 0, 0)",
                    }}
                  ></span>
                  Unclaimed Tree
                </div>
              )}
              {!this.state.onlyYourTrees && (
                <div>
                  <span
                    style={{
                      backgroundColor: "rgb(0, 0, 0)",
                      border: "1px solid rgb(0, 0, 0)",
                    }}
                  ></span>
                  Other Driver's Tree
                </div>
              )}
            </Card>
          )}
          <Map
            id="map"
            ref={(ref) => {
              if (ref) {
                map = ref.getMap();
                map.on('idle', function () {
                  map.resize()
                });
                if (!map.hasImage("truck-dark") || !map.hasImage("truck-light")) {
                  map.loadImage('/assets/img/map/truck-dark.png', (error: any, image: any) => {
                    if (error) throw error;
                    map.addImage('truck-dark', image);
                  });
                  map.loadImage('/assets/img/map/truck-light.png', (error: any, image: any) => {
                    if (error) throw error;
                    map.addImage('truck-light', image);
                  });
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: -122.21,
              latitude: 47.63,
              zoom: 13,
            }}
            mapStyle={this.props.theme === "light" ?
              "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
              "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
            onClick={(e) => {
              const features = map?.queryRenderedFeatures(e.point);
              if (features) {
                const displayProperties = [
                  "type",
                  "properties",
                  "id",
                  "layer",
                  "source",
                  "sourceLayer",
                  "state",
                ];
                const displayFeatures = features.map((feat: any) => {
                  const displayFeat: any = {};
                  displayProperties.forEach((prop) => {
                    displayFeat[prop] = feat[prop];
                  });
                  return displayFeat;
                });
                const clickedCoordinates = e.lngLat;
                this.addrClick(displayFeatures, clickedCoordinates);
              }
            }}
            maxBounds={MAP_BOUNDS}
          >
            <GeolocateControl
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />
            {LAYERS.filter((layer) => {
              if (this.state.onlyYourTrees) {
                return layer.name.includes("yours");
              } else {
                return true;
              }
            }).map((layer) => {
              let geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: "FeatureCollection",
                features: this.props.trees
                  .filter((tree) => layer.filter(tree))
                  .map((tree) => {
                    const treeCoordinates = [tree.lat, tree.lng];
                    const treeID = tree.id;
                    const treeAddress = tree.address;
                    return {
                      type: "Feature",
                      properties: {
                        tree: tree,
                        description: treeAddress,
                        icon: "tree",
                        "icon-image": "tree",
                        id: treeID,
                      },
                      geometry: {
                        type: "Point",
                        coordinates: treeCoordinates,
                      },
                    };
                  }),
              };
              return (
                <Source
                  id={layer.name}
                  type="geojson"
                  data={geoJSON}
                  key={layer.name}
                >
                  <Layer
                    id={layer.name}
                    type="circle"
                    paint={{
                      "circle-color": layer.color,
                      "circle-radius": {
                        'base': 2.75,
                        'stops': [
                          [12, 5],
                          [22, 180]
                        ]
                      },
                      "circle-stroke-width": 4,
                      "circle-stroke-color": layer.outline,
                    }}
                  />
                </Source>
              );
            })}
            {this.props.groups.filter(
              // if filtering for yours, only show yours
              (pickup_group) => pickup_group.name !== "Quad #0" &&
                (this.state.onlyYourTrees
                  ? pickup_group.assigned_to === -1
                  : true)
            )
              .map((pickup_group) => {
                let color = "#555555"; // Other driver
                if (pickup_group.yours) {
                  color = "#059c31";
                } else if (pickup_group.assigned_to <= 0) {
                  color = "#ff0000";
                }
                const BUFFER_SIZE = 0.0004;
                const rectangle = {
                  type: "Feature",
                  properties: {
                    group: pickup_group,
                    color: color,
                    id: pickup_group.id,
                  },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [pickup_group.min_lat - BUFFER_SIZE, pickup_group.min_lng - BUFFER_SIZE],
                        [pickup_group.min_lat - BUFFER_SIZE, pickup_group.max_lng + BUFFER_SIZE],
                        [pickup_group.max_lat + BUFFER_SIZE, pickup_group.max_lng + BUFFER_SIZE],
                        [pickup_group.max_lat + BUFFER_SIZE, pickup_group.min_lng - BUFFER_SIZE],
                        [pickup_group.min_lat - BUFFER_SIZE, pickup_group.min_lng - BUFFER_SIZE],
                      ],
                    ],
                  },
                };
                return rectangle;
              })
              .map((feature: any) => {
                return (
                  <Source
                    id={"group_" + feature.properties.id}
                    type="geojson"
                    data={feature}
                    key={feature.properties.id}
                  >
                    <Layer
                      id={"group_" + feature.properties.id}
                      type="fill"
                      paint={{
                        "fill-color": feature.properties.color,
                        "fill-opacity": 0.3,
                      }}
                    />
                  </Source>
                );
              })}
            {/* Source for driver locations */}
            <Source id="driver_locations" type="geojson" data={
              {
                type: "FeatureCollection",
                features: this.state.driverLocations.map((driver: DriverLocation) => {
                  return {
                    type: "Feature",
                    properties: {
                      description:
                        driver.username.toLowerCase() +
                        "\n" +
                        relativeTime(driver.timestamp),
                      "database-id": driver.id,
                      timestamp: driver.timestamp,
                      // html: initials.join(""),
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [driver.lng, driver.lat],
                    },
                  };
                })
              }
            }>
              <Layer
                id="driver_locations"
                type="symbol"
                layout={{
                  "text-field": ["get", "description"],
                  "text-variable-anchor": ["top", "bottom", "left", "right"],
                  "text-radial-offset": 1,
                  "text-justify": "auto",
                  "icon-image": this.props.theme === "dark" ? "truck-light" : "truck-dark",
                  "icon-size": 0.25,
                }}
                paint={{
                  "text-color": this.props.theme === "dark" ? "#fff" : "#000",
                }}
              />
            </Source>
          </Map>
        </MapProvider>
      </div>
    );
  }

  addrClick(objs: any, coordinates: any) {
    const treeObj = objs.find((obj: any) => {
      return LAYERS.some((layer) => layer.name === obj.source);
    });
    const groupObj = objs.find((obj: any) => {
      return obj.source.includes("group_");
    });
    if (treeObj) {
      // If marker exists at click location, open popup
      const tree = JSON.parse(treeObj.properties["tree"]);
      this.props.onTreeClick(tree);
    } else if (groupObj) {
      const group = JSON.parse(groupObj.properties["group"]);
      this.props.onGroupClick(group);
    }
  }
}

function relativeTime(time: string) {
  const d = new Date(time);
  d.setHours(d.getHours() + 8);
  const diff = ((new Date().getTime() - d.getTime()) / 1000);
  if (diff < 60) {
    return "just now";
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + "m ago";
  }
  return Math.floor(diff / 3600) + "h ago";
}

function isCompleted(status: number) {
  return status !== 0;
}

export default PickupMap;
