import Card from "@mui/material/Card";
import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";

interface ScoutStatsProps { }

interface ScoutStatsState {
  scouts: Scout[];
}

interface Scout {
  id: number;
  name: string;
  total_sales: number;
}

class ScoutStats extends Component<ScoutStatsProps, ScoutStatsState> {
  constructor(props: ScoutStatsProps) {
    super(props);
    this.state = {
      scouts: [],
    };
  }

  loadScouts() {
    callAPI("scouts/")
      .then((response: any) => {
        this.setState({ scouts: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadScouts();
  }

  render() {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h2>
              Scouts Stats{" "}
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.loadScouts();
                }}
              >
                Refresh
              </button>
            </h2>
          </div>
        </div>
        <div className="row">
          {this.state.scouts?.map((scout) => (
            <div className="col-md-4 col-sm-12 p-2" key={scout.id}>
              <Card variant="outlined" className="p-3">

                <h2>{scout.name}</h2>
                <p>
                  {formatter.format(scout.total_sales)}{" "}
                  <span className="small text-muted">total sales</span>
                </p>
                <Link
                  className="btn btn-outline-primary m-2"
                  to={"/scouts/" + scout.id + "/"}
                >
                  View Dashboard
                </Link>
              </Card>
            </div>
          ))}
          {this.state.scouts.length === 0 && (
            <div className="col-md-4 col-sm-12 p-2">
              <Card variant="outlined" className="p-3">
                <h2>No Scouts</h2>
                <p>
                  <span className="small text-muted">Scouts will appear here once they have sales.</span>
                </p>
              </Card>
            </div>
          )
          }
        </div>
      </div >
    );
  }
}

export default ScoutStats;
