import { Component } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { callAPI, unCacheAPI } from "../../utils/API";
import { PickupGroup } from "./ManagePickupGroups";

interface AssignGroupModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  group: PickupGroup;
}

interface AssignGroupModalState {
  driver_id: number;
  drivers: Driver[];
}

interface Driver {
  first_name: string;
  last_name: string;
  username: string;
  driver_id: number;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class AssignGroupModal extends Component<
  AssignGroupModalProps,
  AssignGroupModalState
> {
  constructor(props: AssignGroupModalProps) {
    super(props);
    this.state = {
      driver_id: -1,
      drivers: [],
    };
  }

  componentDidMount() {
    callAPI("pickup/drivers").then((res) => {
      this.setState({ drivers: res.drivers });
    });
  }

  save() {
    callAPI("pickup-groups/", "POST", {
      action: "assign",
      id: this.props.group.id,
      driver_id: this.state.driver_id,
    }).then(() => {
      unCacheAPI("pickup-groups/");
      this.props.onClose();
      this.props.onUpdate();
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addAssignGroupModalLabel">
                Assign Group
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="driver">Driver</label>
                  <select
                    className="form-control"
                    id="driver"
                    value={this.state.driver_id}
                    onChange={(e) =>
                      this.setState({ driver_id: parseInt(e.target.value) })
                    }
                  >
                    <option value={-1}>Select Driver</option>
                    {this.state.drivers.map((driver) => (
                      <option key={driver.driver_id} value={driver.driver_id}>
                        {driver.first_name + " " + driver.last_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  this.save();
                }}
              >
                Assign
              </button>
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default AssignGroupModal;
