import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";
import ResponseChart from "../dashboard/ResponseChart";
import QuadCard from "../quads/QuadCard";
import { Quad } from "../quads/QuadsPage";
import { TreeTagRewardLevel } from "../dashboard/Dashboard";

interface ScoutDashboardProps {
  id: number;
}

interface ScoutDashboardState {
  stats: TreeTagStats | null;
  assigned_quads: Quad[] | null;
  hours: EfficiencyEntry[];
}

interface TreeTagStats {
  total_sales: number;
  reward: TreeTagRewardStatus;
  total_tags: number;
  avg_donation: number;
  goal: number;
  manual_total: number;
  first_name: string;
  reward_levels: TreeTagRewardLevel[];
}

interface TreeTagRewardStatus {
  draws: number;
  level_name: string;
  msg: string;
  rate: number;
}

interface EfficiencyEntry {
  date: string;
  efficiency: number;
  hours: number;
  money: number;
}

class ScoutDashboardWithID extends Component<ScoutDashboardProps, ScoutDashboardState> {
  constructor(props: ScoutDashboardProps) {
    super(props);
    this.state = {
      stats: null,
      assigned_quads: null,
      hours: [],
    };
  }

  loadStats() {
    callAPI("your-stats/?user_id=" + this.props.id)
      .then((response: any) => {
        this.setState({ stats: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  loadQuads() {
    callAPI("quads/?assigned&user_id=" + this.props.id)
      .then((response: any) => {
        this.setState({ assigned_quads: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  loadHours() {
    callAPI("hours/?user_id=" + this.props.id)
      .then((response: any) => {
        this.setState({ hours: response.data });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadStats();
    this.loadQuads();
    this.loadHours();
  }

  getBadgeColor(level: string | undefined) {
    switch (level) {
      case "Bronze":
        return "#28a745";
      case "Silver":
        return "#c0c0c0";
      case "Gold":
        return "#ffc107";
      case "Platinum":
        return "#007bff";
      case "Diamond":
        return "#dc3545";
      default:
        return "#28a745";
    }
  }

  getReward(): TreeTagRewardLevel {
    let reward: TreeTagRewardLevel = {
      draws: 0,
      name: "",
      msg: "Calculating your rewards...",
      rate: 0,
      threshold: 0,
    };
    let nextReward: TreeTagRewardLevel | null = null;

    if (this.state.stats) {
      let total = this.state.stats.total_sales;
      for (let i = 0; i < this.state.stats.reward_levels.length; i++) {
        let level = this.state.stats.reward_levels[i];
        if (total >= level.threshold) {
          reward = level;
          nextReward = this.state.stats.reward_levels[i + 1] || null;
        }
      }

      if (nextReward) {
        let moreSales = nextReward.threshold - total;
        reward.msg = `This scout can sell $${moreSales} more to keep ${nextReward.rate * 100}% of their money and get ${nextReward.draws - reward.draws} extra draws!`;
      } else {
        reward.msg = "This scout has reached the highest reward level!";
      }
    }

    return reward;
  }

  render() {
    const reward = this.getReward();
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h2>{this.state.stats?.first_name}'s Goal</h2>
                {this.state.stats?.goal ? (
                  <div>
                    <p>
                      {formatter.format(
                        this.state.stats?.total_sales as number
                      )}{" "}
                      / {formatter.format(this.state.stats?.goal as number)}
                    </p>
                  </div>
                ) : (
                  <p>
                    {this.state.stats?.first_name} hasn't set a goal.
                  </p>
                )}
                <hr />
                <h2>
                  Estimated Earnings{" "}
                  <span
                    className="badge"
                    style={{
                      backgroundColor: this.getBadgeColor(
                        reward.name
                      ),
                    }}
                    title={reward.name}
                  >
                    {reward.name}
                  </span>
                </h2>
                <h1 className="display-4">
                  {this.state.stats &&
                    formatter.format(
                      this.state.stats?.total_sales *
                      reward.rate
                    )}
                </h1>
                <p className="text-muted">
                  🎁 {reward.draws} Gift Card Drawings
                </p>
                <div className="alert alert-info">
                  <p>{reward.msg}</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 row">
                <div className="col-lg-6 col-sm-12">
                  <h2>
                    {this.state.stats?.first_name}'s Stats{" "}
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.loadStats();
                      }}
                    >
                      Refresh
                    </button>
                  </h2>
                  <h4>
                    Total Sales:{" "}
                    {formatter.format(this.state.stats?.total_sales as number)}
                  </h4>

                  <h4>Total Tree Tags Sold: {this.state.stats?.total_tags}</h4>
                  <h4>
                    Avg Donation:{" "}
                    {formatter.format(this.state.stats?.avg_donation as number)}
                  </h4>
                  {/* <h4>Avg Tag Sale: {formatter.format(-1)}</h4> */}
                </div>
                <div className="col-lg-6 col-sm-12">
                  <ResponseChart user={this.props.id} />
                </div>
                <div className="col-12">
                  <hr />
                  <h2>Efficiency</h2>
                  {this.state.hours.map((entry) => (
                    <div key={entry.date}>
                      <p className="text-muted mb-0">
                        {new Date(entry.date).toLocaleDateString()}: {formatter.format(entry.efficiency)}{"/hr"}
                      </p>
                      <p className="text-muted">
                        {formatter.format(entry.money)} earned in {entry.hours}{" "}
                        {entry.hours === 1 ? "hour" : "hours"}
                      </p>
                    </div>
                  ))}
                  {this.state.hours.length === 0 && (
                    <p>
                      {this.state.stats?.first_name} hasn't entered any hours yet.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <Card className="mt-2 p-3">
              <h3>{this.state.stats?.first_name}'s Quads</h3>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {this.state.assigned_quads?.map((quad) => (
                  <QuadCard key={quad.quad_id} quad={quad} />
                ))}
                <div className="col">
                  <Card variant="outlined" className="p-3">
                    <h2>Manual Entries</h2>
                    <h3>
                      {formatter.format(
                        this.state.stats?.manual_total || 0
                      )}
                    </h3>
                  </Card>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  setGoal() {
    let ammount = prompt("Enter {this.state.stats?.first_name}'s goal in dollars");
    if (ammount && parseFloat(ammount)) {
      callAPI("{this.state.stats?.first_name}'s-stats/", "POST", { goal: parseFloat(ammount) }).then(
        (data) => {
          unCacheAPI("{this.state.stats?.first_name}'s-stats/");
          this.loadStats();
        }
      );
    } else {
      toast.error("Please enter a valid number", { id: "error" });
    }
  }
}

export default function ScoutDashboard() {
  const { id } = useParams();
  if (id && !isNaN(Number(id))) {
    return <ScoutDashboardWithID id={Number(id)} />;
  }
  return <div>Invalid Scout</div>;
}