import Card from "@mui/material/Card";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";

interface ManageSalesProps { }

interface ManageSalesState {
  thisYearTotalSales: number;
  thisYearTotalTags: number;
  thisYearTotalDonations: number;
  thisYearTotalTagSales: number;
  thisYearEmailList: string[];
  previousYearsEmailList: string[];
  currentTagPrice: number;
}

class ManageSales extends Component<ManageSalesProps, ManageSalesState> {
  constructor(props: ManageSalesProps) {
    super(props);
    this.state = {
      thisYearTotalSales: 0,
      thisYearTotalTags: 0,
      thisYearTotalDonations: 0,
      thisYearTotalTagSales: 0,
      thisYearEmailList: [],
      previousYearsEmailList: [],
      currentTagPrice: 0,
    };
  }

  loadData() {
    callAPI("manage/")
      .then((response: any) => {
        this.setState({
          thisYearTotalSales: response.this_year_stats.total_sales,
          thisYearTotalTags: response.this_year_stats.total_tags,
          thisYearTotalDonations: response.this_year_stats.total_donations,
          thisYearTotalTagSales: response.this_year_stats.total_tag_sales,
          thisYearEmailList: response.this_year_email_list,
          previousYearsEmailList: response.previous_years_email_list,
          currentTagPrice: response.tag_price,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  editTagPrice() {
    callAPI("manage/", "PUT", {
      current_tag_price: this.state.currentTagPrice,
    })
      .then((response: any) => {
        this.loadData();
      })
      .catch((error: any) => {
        toast.error("Error editing tag price");
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h2>
              Manage Sales{" "}
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.loadData();
                }}
              >
                Refresh
              </button>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Card className="p-3">
              <h3>This Year's Stats</h3>
              <h1 className="display-4">
                {formatter.format(this.state.thisYearTotalSales)}
              </h1>
              <p className="text-muted mb-0">
                {this.state.thisYearTotalTags} Tags Sold
              </p>
              <p className="text-muted mb-0">
                {formatter.format(this.state.thisYearTotalDonations)} from donations ({(this.state.thisYearTotalDonations / this.state.thisYearTotalSales * 100).toFixed(0)}%)
              </p>
              <p className="text-muted mb-0">
                {formatter.format(this.state.thisYearTotalTagSales)} from tag sales ({(this.state.thisYearTotalTagSales / this.state.thisYearTotalSales * 100).toFixed(0)}%)
              </p>
            </Card>
          </div>
          <div className="col-md-6 col-sm-12">
            <Card className="p-3">
              <h3>Current Tag Price</h3>
              <h1 className="display-3">
                {formatter.format(this.state.currentTagPrice)}
              </h1>
              <div className="input-group">
                <span className="input-group-text">$</span>
                <input
                  disabled
                  type="number"
                  className="form-control"
                  value={this.state.currentTagPrice}
                  onChange={(e) => {
                    this.setState({
                      currentTagPrice: parseInt(e.target.value),
                    });
                  }}
                />
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => {
                    this.editTagPrice();
                  }}
                >
                  Edit
                </button>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Card className="p-3 mt-3">
              <h3>This Year's Email List</h3>
              <p className="text-muted">{this.state.thisYearEmailList.length} emails</p>
              <textarea
                className="form-control"
                rows={5}
                value={this.state.thisYearEmailList.join(", ")}
                readOnly
              />
            </Card>
          </div>
          <div className="col-md-6 col-sm-12">
            <Card className="p-3 mt-3">
              <h3>Previous Years' Email List</h3>
              <p className="text-muted">{this.state.previousYearsEmailList.length} emails</p>
              <textarea
                className="form-control"
                rows={5}
                value={this.state.previousYearsEmailList.join(", ")}
                readOnly
              />
            </Card>
          </div>
        </div>
      </div >
    );
  }
}

export default ManageSales;
