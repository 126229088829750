import { Component } from "react";
import Card from "@mui/material/Card";
import { PickupGroup } from "../pickup/ManagePickupGroups";

interface PickupGroupCardProps {
  group: PickupGroup;
  editable?: boolean;
}

interface PickupGroupCardState { }

class PickupGroupCard extends Component<PickupGroupCardProps, PickupGroupCardState> {
  constructor(props: PickupGroupCardProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="col">
        <Card variant="outlined" className="m-2 p-3">
          <h5 className="card-title">{this.props.group.name}</h5>
          <h3>
            {this.props.group.num_collected} /{" "}
            {this.props.group.num_collected + this.props.group.num_left} collected (
            {(
              this.props.group.num_collected /
              (this.props.group.num_collected + this.props.group.num_left) *
              100
            ).toFixed(1)}
            %)
          </h3>
          {/* {this.props.editable && (
            <Link
              to={"/quads/" + this.props.group.quad_id + "/edit"}
              className="btn btn-link ms-2"
            >
              Unclaim
            </Link>
          )} */}
        </Card>
      </div>
    );
  }
}

export default PickupGroupCard;
