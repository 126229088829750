import Card from "@mui/material/Card";
import React, { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, unCacheAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";
import ManualEntryModal from "./ManualEntryModal";

interface ManualEntriesProps { }

interface ManualEntriesState {
  entries: ManualEntry[];
  loaded: boolean;
  addModalOpen: boolean;
}

export interface ManualEntry {
  id: number;
  money: number;
  notes: string;
  timestamp: string;
}

class ManualEntries extends Component<ManualEntriesProps, ManualEntriesState> {
  constructor(props: ManualEntriesProps) {
    super(props);
    this.state = {
      entries: [],
      loaded: false,
      addModalOpen: false,
    };
  }

  loadEntries() {
    callAPI("manual/").then((data: any) => {
      this.setState({ entries: data.data, loaded: true });
    });
  }

  componentDidMount() {
    this.loadEntries();
  }

  render() {
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12">
            <h1>Manual Entries</h1>
            <p>
              If you forgot to record a donation or are having issues with the
              map, record donations manually here.
            </p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.setState({ addModalOpen: true })}
            >
              Add Manual Entry
            </button>
            <hr />
          </div>
          <div className="col-12">
            <div className="row">
              {this.state.entries.map((entry) => {
                return (
                  <div className="col-12 p-3" key={entry.id}>
                    <Card variant="outlined" className="p-3">
                      <h5 className="card-title">
                        {formatter.format(entry.money)}
                      </h5>
                      <p className="card-text">
                        {new Date(entry.timestamp).toLocaleDateString()}
                      </p>
                      <p className="card-text">{entry.notes}</p>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.deleteEntry(entry.id);
                        }}
                      >
                        Delete
                      </button>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ManualEntryModal
          open={this.state.addModalOpen}
          onClose={() => this.setState({ addModalOpen: false })}
          onSave={() => {
            this.loadEntries();
          }}
        />
      </div>
    );
  }

  deleteEntry(id: number) {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      callAPI("manual/", "POST", {
        id: id,
        action: "delete",
      })
        .then(() => {
          unCacheAPI("manual/");
          this.loadEntries();
        })
        .catch((err) => {
          toast.error("Error deleting entry", { id: "error" });
        });
    }
  }
}

export default ManualEntries;
