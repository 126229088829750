import { Component } from "react";
import { Quad } from "./QuadsPage";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/Formatter";
import Card from "@mui/material/Card";

interface QuadCardProps {
  quad: Quad;
  editable?: boolean;
}

interface QuadCardState { }

class QuadCard extends Component<QuadCardProps, QuadCardState> {
  constructor(props: QuadCardProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="col">
        <Card variant="outlined" className="m-2 p-3">
          <h5 className="card-title">{"Quad #" + this.props.quad.quad_id}</h5>
          {this.props.editable && this.props.quad.assigned_to !== "" && (
            <p className="card-text">
              Assigned to: {this.props.quad.assigned_to}
            </p>
          )}
          {this.props.editable && (
            <h3>{formatter.format(this.props.quad.money)}</h3>
          )}
          <p>Last year: {formatter.format(this.props.quad.money_last_year)}</p>
          <h3>
            {this.props.quad.addresses_visited} /{" "}
            {this.props.quad.addresses_in_quad} visited (
            {(
              this.props.quad.addresses_visited /
              this.props.quad.addresses_in_quad *
              100
            ).toFixed(1)}
            %)
          </h3>
          {this.props.quad.money_by_scout.map((moneyByScout) => (
            <p className="text-muted" key={moneyByScout.name}>
              {moneyByScout.name}: {formatter.format(moneyByScout.total_money)}
            </p>
          ))}
          <Link
            to={"/map/sales/" + this.props.quad.quad_id + "/"}
            className="btn btn-primary"
          >
            View Map
          </Link>
          {this.props.editable && (
            <Link
              to={"/quads/" + this.props.quad.quad_id + "/edit"}
              className="btn btn-link ms-2"
            >
              Edit
            </Link>
          )}
        </Card>
      </div>
    );
  }
}

export default QuadCard;
