import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import { formatter, TREE_TAG_PRICE } from "../../utils/Formatter";
import { formatEmail, formatPhone } from "../pickup/TagInfo";
import { shortenAddress } from "../quads/HouseInfo";
import UserTagify from "../quads/UserTagify";
import PickupEditorModal from "./PickupEditorModal";

interface AllTagsProps {
  theme: "light" | "dark";
}

interface AllTagsState {
  tags: Tag[];
  duplicateTagAddresses: string[];
  loaded: boolean;
  editingTreeID: number;
  searchTerm: string;
  allTypes: boolean;
  scout_filter: string;
}

interface Tag {
  id: number;
  address: string;
  name: string;
  email: string;
  phone: string;
  tags: number;
  collected: number;
  scout: string;
  entered_by: string;
  shopify_id: string;
}

function matchesSearch(tag: Tag, searchTerm: string) {
  const fields = [
    tag.address,
    tag.name,
    tag.email,
    tag.phone,
    tag.scout,
    tag.entered_by,
  ];
  return fields.some((field) => field && field.toLowerCase().includes(searchTerm.toLowerCase()));
}

class AllTags extends Component<AllTagsProps, AllTagsState> {
  constructor(props: AllTagsProps) {
    super(props);
    this.state = {
      tags: [],
      duplicateTagAddresses: [],
      loaded: false,
      editingTreeID: 0,
      searchTerm: "",
      allTypes: false,
      scout_filter: "",
    };
  }

  loadTags() {
    callAPI(
      (this.state.allTypes ? "list/?all=true&all_types=true" : "list/?all=true") + "&scout=" + this.state.scout_filter
    )
      .then((response) => {
        this.setState({
          tags: response.results,
          loaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadDuplicates() {
    callAPI("pickup/stats?action=find_duplicates")
      .then((response) => {
        this.setState({
          duplicateTagAddresses: response.duplicates,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadTags();
    this.loadDuplicates();
  }

  render() {
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12 no-print">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Search
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search by address, name, email, or phone"
                aria-label="Search"
                value={this.state.searchTerm}
                onChange={(e) => {
                  this.setState({ searchTerm: e.target.value });
                }}
              />
              {/* <div className="input-group-append">
                <a
                  className="btn btn-outline-secondary"
                  type="button"
                  href="/api/v1/tree-tags/list/csv"
                >
                  Download CSV
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-12 no-print">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text h-100" id="basic-addon1">
                  Scout
                </span>
              </div>
              <div className="col-8">
                <UserTagify
                  defaultValue={this.state.scout_filter?.replaceAll('"', "")}
                  onChange={(value) => {
                    this.setState({ scout_filter: value },
                      () => this.loadTags());
                  }}
                  placeholder="Filter by scout"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <Link className="btn btn-outline-primary me-2 mb-1 w-xs-100" to="/manage">
              View Email Addresses
            </Link>
            <button
              className="btn btn-outline-primary me-2 mb-1 w-xs-100"
              onClick={() => {
                unCacheAPI(
                  this.state.allTypes
                    ? "list/?all=true&all_types=true"
                    : "list/?all=true"
                );
                this.loadTags();
              }}
            >
              Refresh
            </button>
            <button
              className="btn btn-outline-primary me-2 mb-1 w-xs-100"
              onClick={() => {
                this.setState({ allTypes: !this.state.allTypes }, () => {
                  this.loadTags();
                });
              }}
            >
              {this.state.allTypes ? "Show Only Tags" : "Show All Types"}
            </button>
            <hr />
            {this.state.duplicateTagAddresses.length > 0 && (
              <div>
                <div className="alert alert-warning">
                  <h4 className="alert-heading">Duplicate Entries</h4>
                  <p>
                    The following addresses have been entered more than once, and will
                    overlap on the pickup map, causing confusion.
                    Please review the list below and resolve any duplicates.
                  </p>
                  <ul>
                    {this.state.duplicateTagAddresses.map((address) => (
                      <li>{shortenAddress(address.split(",")[0])}</li>
                    ))}
                  </ul>
                </div>
                <hr />
              </div>
            )}
            {this.state.tags.length > 0 ? (
              <table className={"table " +
                (this.props.theme === "dark" && "text-white")}>
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th># Tags</th>
                    <th>$ Collected</th>
                    <th>$ Owed</th>
                    <th>Scout</th>
                    <th>Entered by</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tags.filter((tag) => {
                    return !this.state.searchTerm || matchesSearch(tag, this.state.searchTerm);
                  }).map((tag) => {
                    let owed = TREE_TAG_PRICE * tag.tags - tag.collected;
                    if (owed < 0) {
                      owed = 0;
                    }
                    return (
                      <tr key={tag.id} data-id={tag.id}>
                        <td>{tag.address}</td>
                        <td>{tag.name}</td>
                        <td>{formatEmail(tag.email)}</td>
                        <td>{formatPhone(tag.phone)}</td>
                        <td>{tag.tags}</td>
                        <td>{formatter.format(tag.collected)}</td>
                        <td>
                          {formatter.format(owed)}
                        </td>
                        <td>{tag.scout + (tag.shopify_id ? " via Shopify" : "")}</td>
                        <td>{tag.entered_by}</td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              this.setState({ editingTreeID: tag.id });
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                {this.state.allTypes ? (
                  <p className="text-muted">No responses found.</p>
                ) : (
                  <p className="text-muted">No tags found.</p>
                )}
              </div>
            )}
          </div>
        </div>
        <PickupEditorModal
          open={this.state.editingTreeID !== 0}
          treeTagID={this.state.editingTreeID}
          onClose={() => {
            this.setState({ editingTreeID: 0 });
          }}
          onSave={() => {
            this.loadTags();
          }}
        />
      </div>
    );
  }
}

export default AllTags;
