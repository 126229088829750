import { Component } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { callAPI } from "../../utils/API";
import "./UserTagify.css";

function tagTemplate(this: any, tagData: any) {
  return `
            <tag title="${tagData.title || tagData.email}"
                    contenteditable='false'
                    spellcheck='false'
                    tabIndex="-1"
                    class="${this.settings.classNames.tag} ${
    tagData.class ? tagData.class : ""
  }"
                    ${this.getAttributes(tagData)}>
                <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
                <div>
                    <div class='tagify__tag__avatar-wrap'>
                        <img onerror="this.style.visibility='hidden'" src="${
                          tagData.avatar
                        }">
                    </div>
                    <span class='tagify__tag-text'>${tagData.name}</span>
                </div>
            </tag>
        `;
}

function suggestionItemTemplate(this: any, tagData: any) {
  return `
            <div ${this.getAttributes(tagData)}
                class='tagify__dropdown__item ${
                  tagData.class ? tagData.class : ""
                }'
                tabindex="0"
                role="option">
                ${
                  tagData.avatar
                    ? `
                <div class='tagify__dropdown__item__avatar-wrap'>
                    <img onerror="this.style.visibility='hidden'" src="${tagData.avatar}">
                </div>`
                    : ""
                }
                <strong>${tagData.name}</strong>
                <span>${tagData.email}</span>
            </div>
        `;
}

interface UserTagifyProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

interface UserTagifyState {
  users: UserTag[];
  value: UserTag[];
}

interface UserTag {
  avatar: string;
  email: string;
  name: string;
  type: string;
  value: string;
}

class UserTagify extends Component<UserTagifyProps, UserTagifyState> {
  constructor(props: UserTagifyProps) {
    super(props);
    this.state = {
      users: [],
      value: [],
    };
  }

  loadUsers() {
    let defaultValue = JSON.parse(this.props.defaultValue || "[]");
    callAPI("/api/v1/tags/users.php").then((data) => {
      this.setState({
        users: data,
        value: data.filter((user: UserTag) => {
          return defaultValue.includes(user.value);
        }),
      });
    });
  }

  componentDidMount() {
    this.loadUsers();
  }

  componentDidUpdate(
    prevProps: Readonly<UserTagifyProps>,
    prevState: Readonly<UserTagifyState>,
    snapshot?: any
  ): void {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      let defaultValue = JSON.parse(this.props.defaultValue || "[]");
      this.setState({
        value: this.state.users.filter((user: UserTag) => {
          return defaultValue.includes(user.value);
        }),
      });
    }
  }

  render() {
    return (
      <Tags
        settings={{
          tagTextProp: "name",
          enforceWhitelist: true,
          skipInvalid: true,
          dropdown: {
            closeOnSelect: false,
            enabled: 0,
            classname: "users-list",
            searchKeys: ["name", "email"],
          },
          templates: {
            tag: tagTemplate,
            dropdownItem: suggestionItemTemplate,
          },
        }}
        whitelist={this.state.users}
        value={this.state.value}
        placeholder={this.props.placeholder}
        // {...tagifyProps}
        onChange={(e) => {
          if (this.props.onChange) {
            try {
              let value = JSON.parse(e.detail.value);
              if (this.state.users.length) {
                // this.setState({ value: value });
              }
              this.props.onChange(
                "[" + value.map((user: UserTag) => user.value).join(",") + "]"
              );
            } catch (e) {
              this.props.onChange("[]");
            }
          }
        }}
      />
    );
  }
}

export default UserTagify;
