import { Component } from "react";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Formatter";

interface LeaderboardProps {
  theme: "light" | "dark";
}

interface LeaderboardState {
  leaderboard: any[];
  loaded: boolean;
  selectedYearOffset: number;
}

class Leaderboard extends Component<LeaderboardProps, LeaderboardState> {
  constructor(props: LeaderboardProps) {
    super(props);
    this.state = {
      leaderboard: [],
      loaded: false,
      selectedYearOffset: 0,
    };
  }

  loadLeaderboard() {
    callAPI("leaderboard/?year_offset=" + this.state.selectedYearOffset)
      .then((res: any) => {
        this.setState({
          leaderboard: res.data,
          loaded: true,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadLeaderboard();
  }

  render() {
    if (this.state.leaderboard.length === 0) {
      return (
        <div className="container mt-2">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-5 fw-bold">No sales yet.</h1>
              <p className="lead mb-2">
                The leaderboard will update when the first scout sells more than
                $100.
              </p>
              <div className="btn-group" role="group" aria-label="Select options and refresh">
                <select value={this.state.selectedYearOffset} onChange={(e) => {
                  this.setState({ selectedYearOffset: Number(e.target.value) }, () => {
                    this.loadLeaderboard();
                  });
                }} className="form-select">
                  <option value={0}>This Year</option>
                  <option value={1}>Last Year</option>
                </select>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => {
                    this.loadLeaderboard();
                  }}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-5 fw-bold">Sales Leaderboard</h1>
            <div className="btn-group" role="group" aria-label="Select options and refresh">
              <select value={this.state.selectedYearOffset} onChange={(e) => {
                this.setState({ selectedYearOffset: Number(e.target.value) }, () => {
                  this.loadLeaderboard();
                });
              }} className="form-select">
                <option value={0}>This Year</option>
                <option value={1}>Last Year</option>
              </select>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.loadLeaderboard();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
          <div className="col-12">
            <table className={"table " +
              (this.props.theme === "dark" && "text-white")}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Sales</th>
                  <th scope="col"># Door Knocks</th>
                  <th scope="col"># Trees</th>
                  <th scope="col">Avg. Donation</th>
                </tr>
              </thead>
              <tbody>
                {this.state.leaderboard.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.name}</td>
                      <td>{formatter.format(item.sales)}</td>
                      <td>{item.num_knocks}</td>
                      <td>{item.num_tag_sales}</td>
                      <td>{formatter.format(item.avg_donation)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Leaderboard;
