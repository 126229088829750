import { Component } from "react";
import { toast } from "react-hot-toast";
import { callAPI, unCacheAPI } from "../../utils/API";
import { TREE_TAG_PRICE } from "../../utils/Formatter";
import GroupInfo from "./GroupInfo";
import { PickupGroup } from "./ManagePickupGroups";
import PickupMap from "./PickupMap";
import TagInfo from "./TagInfo";

interface PickupPageProps {
  theme: "light" | "dark";
}

export interface Tag {
  pickup_group_valid: boolean;
  pickup_group: number;
  yours: boolean;
  paid: boolean;
  layerType: string;
  lat: number;
  lng: number;
  id: string;
  address: string;
  house_id: string;
  picked_up_status: any;
  driver_assigned: number | null;
  money: number;
  money_later: number;
  num_tags: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  timestamp: string;
  pickup_note: string;
  comment: string;
  scout: string;
}

interface PickupPageState {
  trees: Tag[];
  pickup_groups: PickupGroup[];
  treeSelected: Tag | null;
  groupSelected: PickupGroup | null;
  assignedQuads: number[];
  updateInterval: NodeJS.Timeout | null;
}

class PickupPage extends Component<PickupPageProps, PickupPageState> {
  constructor(props: PickupPageProps) {
    super(props);
    this.state = {
      trees: [],
      pickup_groups: [],
      treeSelected: null,
      groupSelected: null,
      assignedQuads: [],
      updateInterval: null,
    };
  }

  loadTags() {
    callAPI("pickup/tags").then((data: any) => {
      const trees = data.tags;
      trees.forEach((tree: Tag) => {
        tree.paid = tree.money >= tree.num_tags * TREE_TAG_PRICE;
      });
      this.setState({ trees });
    });
  }

  loadPickupGroups() {
    callAPI("pickup-groups/").then((data: any) => {
      this.setState({
        pickup_groups: data.data,
      });
    });
  }

  componentDidMount() {
    this.loadTags();
    this.loadPickupGroups();
    if (window.location.protocol === "https:") {
      // Only use interval on production deployment
      this.setState({
        updateInterval: setInterval(() => {
          this.loadTags();
          this.loadPickupGroups();
        }, 60 * 1000),
      });
    }
  }

  componentWillUnmount() {
    if (this.state.updateInterval) {
      clearInterval(this.state.updateInterval);
    }
  }

  render() {
    let overlay = null;
    if (this.state.treeSelected) {
      overlay = (
        <TagInfo
          tree={this.state.treeSelected}
          onClose={() => {
            this.setState({ treeSelected: null });
          }}
          onUpdate={() => {
            if (this.state.treeSelected) {
              this.updateTag(this.state.treeSelected);
            }
          }}
          theme={this.props.theme}
        />
      );
    } else if (this.state.groupSelected) {
      overlay = (
        <GroupInfo
          group={this.state.groupSelected}
          onClose={() => {
            this.setState({ groupSelected: null });
          }}
          onUpdate={() => {
            unCacheAPI("pickup-groups/");
            this.loadPickupGroups();
            this.loadTags();
          }}
          theme={this.props.theme}
        />
      );
    }
    return (
      <div className="h-100">
        {overlay}
        <div className="h-100" style={{ display: overlay !== null ? "none" : "block" }}>
          <PickupMap
            theme={this.props.theme}
            trees={this.state.trees}
            groups={this.state.pickup_groups}
            onTreeClick={(clickedTree) => {
              this.setState({ treeSelected: clickedTree });
            }}
            onGroupClick={(clickedGroup) => {
              this.setState({ groupSelected: clickedGroup });
            }}
            onRefreshRequest={() => {
              unCacheAPI("address/");
              this.loadTags();
              unCacheAPI("pickup-groups/");
              this.loadPickupGroups();
            }}
            treeSelected={this.state.treeSelected}
            groupSelected={this.state.groupSelected}
          />
        </div>
      </div>
    );
  }

  updateTag(tag: Tag) {
    callAPI("pickup/tags?id=" + tag.id)
      .then((data: any) => {
        const tree = data.tag;
        const trees = this.state.trees;
        const index = trees.findIndex((t) => t.id === tag.id);
        tree.paid = tree.money >= tree.num_tags * TREE_TAG_PRICE;
        trees[index] = tree;
        this.setState({ trees });
      })
      .catch((err) => {
        toast.error("Error updating tag: " + err, { id: "error" });
      });
  }
}

export default PickupPage;
