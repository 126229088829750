import { Component } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";
import { shortenAddress } from "../quads/HouseInfo";
import { House } from "../quads/QuadPage";
import LocationEditor from "./LocationEditor";

interface HouseEditorProps {
  theme: "light" | "dark";
  id: number;
}

interface HouseEditorState {
  house: House | null;
}

class HouseEditorWithID extends Component<HouseEditorProps, HouseEditorState> {
  constructor(props: HouseEditorProps) {
    super(props);
    this.state = {
      house: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI("address/?action=get_house_info&house_id=" + this.props.id)
      .then((data: any) => {
        this.setState({ house: data.houses[0] });
      });
  }

  deleteHouse() {
    let input = window.prompt(
      "Are you sure you want to delete this house? This cannot be undone.\nType 'delete house' to confirm."
    );
    if (input === "delete house") {
      callAPI("address/", "POST", {
        action: "delete_house",
        house_id: this.props.id
      })
        .then((res) => {
          unCacheAPI("address/?house_id=" + this.props.id);
          window.location.href = "/houses";
        })
        .catch((err) => {
          toast.error("Error deleting house", { id: "error" });
          console.log(err);
        });
    } else {
      toast.error("House not deleted", { id: "error" });
    }
  }

  changeQuad() {
    const newQuad = parseInt(window.prompt("Enter new quad ID") || "");
    if (!isNaN(newQuad)) {
      callAPI("address/", "POST", {
        action: "update_house_quad",
        house_id: this.props.id,
        quad_id: newQuad
      })
        .then((res) => {
          unCacheAPI("address/?house_id=" + this.props.id);
          this.loadData();
        })
        .catch((err) => {
          toast.error("Error updating house's quad", { id: "error" });
          console.log(err);
        });
    }
  }

  changeLocation(newCoords: [number, number]) {
    callAPI("address/", "POST", {
      action: "update_house_location",
      house_id: this.props.id,
      lat: newCoords[0],
      lng: newCoords[1],
    })
      .then((res) => {
        unCacheAPI("address/?house_id=" + this.props.id);
        this.loadData();
      })
      .catch((err) => {
        toast.error("Error updating house's location", { id: "error" });
        console.log(err);
      });
  }

  render() {
    if (!this.state.house) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-5 fw-bold">House not found</h1>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-5 fw-bold">{shortenAddress(this.state.house.address.split(",")[0])}</h1>
          </div>
          <div className="col-12 text-center">
            <p>
              <code>
                {JSON.stringify(this.state.house)}
              </code>
            </p>
            <p>
              <strong>Quad:</strong> <Link to={"/map/sales/" + this.state.house.quad_id}>{this.state.house.quad_id}</Link>
              <button
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={() => this.changeQuad()}
              >
                Change
              </button>
            </p>
            <p>
              <strong>Coordinates:</strong> [{this.state.house.lat}, {this.state.house.lng}]
            </p>
            <LocationEditor
              theme={this.props.theme}
              coords={[this.state.house.lat, this.state.house.lng]}
              onChange={(coords) => this.changeLocation(coords)}
            />
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => this.deleteHouse()}
            >
              Delete House
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default function HouseEditor({ theme }: { theme: "light" | "dark" }) {
  const { id } = useParams();
  return <HouseEditorWithID id={parseInt(id || "")} theme={theme} />;
};
