import { Component } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { callAPI } from "../../utils/API";
import { Tag } from "./PickupPage";
import SelectPickupGroup from "./SelectPickupGroup";

interface MoveTagToGroupModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  tag: Tag;
  theme: "light" | "dark";
}

interface MoveTagToGroupModalState {
  other_id: number;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class MoveTagToGroupModal extends Component<
  MoveTagToGroupModalProps,
  MoveTagToGroupModalState
> {
  constructor(props: MoveTagToGroupModalProps) {
    super(props);
    this.state = {
      other_id: -1,
    };
  }

  save() {
    callAPI("pickup-groups/", "POST", {
      action: "move",
      response_id: this.props.tag.id,
      group_id: this.state.other_id,
    }).then(() => {
      this.props.onClose();
      this.props.onUpdate();
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addMoveTagToGroupModalLabel">
                Move Tag to Group
              </h4>
            </div>
            <div className="modal-body" style={{ height: "50vh" }}>
              <SelectPickupGroup
                theme={this.props.theme}
                onGroupSelect={(id) => {
                  this.setState({ other_id: id });
                }}
                center={[this.props.tag.lat, this.props.tag.lng]}
              />
              <div className="mt-3">
                <b>Current Group: #{this.props.tag.pickup_group}</b><br />
                {this.state.other_id !== -1 ? (
                  <b>Selected Group: #{this.state.other_id}</b>
                ) : (
                  <b>No Group Selected</b>
                )}
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  this.save();
                }}
                disabled={this.state.other_id === -1}
              >
                Move
              </button>
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default MoveTagToGroupModal;
