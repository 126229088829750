import { Component } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import DrawPolygon from "./DrawPolygon";
import UserTagify from "./UserTagify";

interface QuadEditorProps {
  theme: "light" | "dark";
  id: number;
}

interface QuadEditorState {
  assigned_scouts: string;
  assigned_scout_names: string;
  polygon: any;
  loaded: boolean;
}

class QuadEditorWithID extends Component<QuadEditorProps, QuadEditorState> {
  constructor(props: QuadEditorProps) {
    super(props);
    this.state = {
      assigned_scouts: "",
      assigned_scout_names: "",
      polygon: null,
      loaded: false,
    };
  }

  loadQuad() {
    callAPI("quads/?id=" + this.props.id).then((data: any) => {
      if (data && data.quad) {
        this.setState({
          assigned_scouts: data.quad.assigned_to_str,
          assigned_scout_names: data.quad.assigned_to,
          polygon: data.quad.polygon,
          loaded: true,
        });
      }
    });
  }

  componentDidMount() {
    this.loadQuad();
  }

  updateQuad() {
    callAPI("", "POST", {
      action: "edit",
      id: this.props.id,
      assigned_scouts: this.state.assigned_scouts,
      polygon: JSON.stringify(this.state.polygon),
    })
      .then((data: any) => {
        window.history.back();
      })
      .catch((err: any) => {
        toast.error("Error updating quad", { id: "error" });
      });
  }

  render() {
    return (
      <div className="container">
        <h1>Quad #{this.props.id}</h1>
        <div className="row">
          <div className="col-12">
            <label htmlFor="assigned_scouts_edit">Assigned Scouts: </label>
            <UserTagify
              defaultValue={this.state.assigned_scouts?.replaceAll('"', "")}
              onChange={(value) => {
                this.setState({ assigned_scouts: value });
              }}
            />
          </div>
          <div className="col-12">
            <DrawPolygon
              theme={this.props.theme}
              polygon={this.state.polygon}
              quad_id={this.props.id}
              onChange={(polygon) => {
                this.setState({ polygon: polygon });
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            this.updateQuad();
          }}
        >
          Save
        </button>
      </div>
    );
  }
}

export default function QuadEditor({ theme }: { theme: "light" | "dark" }) {
  const { quadId } = useParams();
  if (quadId && !isNaN(Number(quadId))) {
    return <QuadEditorWithID id={Number(quadId)} theme={theme} />;
  }
  return <div>Invalid Quad</div>;
}
