import { Component } from "react";
import { Modal, Slider } from "@mui/material";
import Box from "@mui/material/Box";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TreeTagRewardLevel } from "./Dashboard";
import { formatter } from "../../utils/Formatter";

interface RewardLevelsModalProps {
  open: boolean;
  onClose: () => void;
  levels: TreeTagRewardLevel[];
}

interface RewardLevelsModalState {
  totalSales: number;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  height: "80%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class RewardLevelsModal extends Component<
  RewardLevelsModalProps,
  RewardLevelsModalState
> {
  constructor(props: RewardLevelsModalProps) {
    super(props);
    this.state = {
      totalSales: 0,
    };
  }

  calculateEarnings = () => {
    const totalSales = Number(this.state.totalSales);
    if (isNaN(totalSales) || totalSales < 0 || totalSales > 10000) return null;
    let level = this.props.levels[0];
    if (!level) return null;
    for (let i = 0; i < this.props.levels.length; i++) {
      if (totalSales >= this.props.levels[i].threshold) {
        level = this.props.levels[i];
      }
    }

    const earnings = totalSales * level.rate;
    return { level: level.name, draws: level.draws, earnings };
  };

  getBadgeColor(level: string | undefined) {
    switch (level) {
      case "Bronze":
        return "#28a745";
      case "Silver":
        return "#c0c0c0";
      case "Gold":
        return "#ffc107";
      case "Platinum":
        return "#007bff";
      case "Diamond":
        return "#dc3545";
      default:
        return "#28a745";
    }
  }

  render() {
    const earningsInfo = this.calculateEarnings();
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addRewardLevelsModalLabel">
                Reward Levels
              </h4>
            </div>
            <div className="modal-body">
              <div className="container p-3">
                <p>
                  The more you sell, the more you earn! Each level increases the percentage
                  of your earnings and gives you extra draws for gift cards.
                </p>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Level</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Earnings</TableCell>
                        <TableCell>Entries</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.levels.map((level) => (
                        <TableRow key={level.name}>
                          <TableCell>{level.name}</TableCell>
                          <TableCell>${level.threshold}</TableCell>
                          <TableCell>{level.rate * 100}%</TableCell>
                          <TableCell>{level.draws}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr />
                <div className="earnings-calculator">
                  <h5>Earnings Calculator</h5>
                  <p>
                    Curious how much you can earn? Use the slider below to
                    estimate your earnings.
                  </p>
                  <Slider
                    value={this.state.totalSales}
                    onChange={(e, value) =>
                      this.setState({ totalSales: value as number })
                    }
                    aria-labelledby="input-slider"
                    min={0}
                    max={3000}
                  />
                  <p>If you sold {formatter.format(this.state.totalSales)}, you would get:</p>
                  {earningsInfo && (
                    <div>
                      <h2>
                        Estimated Earnings{" "}
                        <span
                          className="badge"
                          style={{
                            backgroundColor: this.getBadgeColor(
                              earningsInfo.level
                            ),
                          }}
                          title={earningsInfo.level}
                        >
                          {earningsInfo.level}
                        </span>
                      </h2>
                      <h1 className="display-4">
                        {formatter.format(
                          earningsInfo.earnings
                        )}
                      </h1>
                      <p className="text-muted">
                        🎁 {earningsInfo.draws} Gift Card Drawings
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default RewardLevelsModal;
