import { Component } from "react";

interface StaticMapProps {
  url: string;
}

interface StaticMapState {
  loaded: boolean;
  error: boolean;
}

class StaticMap extends Component<StaticMapProps, StaticMapState> {
  constructor(props: StaticMapProps) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    };
  }

  componentDidUpdate(prevProps: StaticMapProps) {
    if (prevProps.url !== this.props.url) {
      this.setState({ loaded: false, error: false });
    }
  }

  render() {
    return (
      <div className="h-auto w-100">
        {!this.state.loaded && !this.state.error && (
          <svg className="bd-placeholder-img card-img-top" width="100%" height="800" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <title>Loading</title>
            <rect width="100%" height="100%" fill="#868e96">
              <animate attributeName="fill" from="#868e96" to="#dee2e6" dur="1s" repeatCount="indefinite" />
            </rect>
          </svg>
        )}
        {this.state.error && (
          <svg className="bd-placeholder-img card-img-top" width="100%" height="800" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <title>Error</title>
            <rect width="100%" height="100%" fill="#60676e" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#fff" fontSize="2rem">⚠️</text>
          </svg>
        )}
        <img
          className="h-auto w-100"
          src={this.props.url}
          alt="Map"
          onLoad={() => this.setState({ loaded: true, error: false })} onError={() => this.setState({ error: true })} style={{ display: this.state.loaded ? "block" : "none" }} />
      </div>
    );
  }
}

export default StaticMap;