import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI, unCacheAPI } from "../../utils/API";

interface TagListProps {
  theme: "light" | "dark";
}

interface TagListState {
  tags: Tag[];
  loaded: boolean;
  searchTerm: string;
  allTypes: boolean;
  onlyWithComment: boolean;
}

export interface Tag {
  id: number;
  address: string;
  name: string;
  email: string;
  phone: string;
  tags: number;
  collected: number;
  scout: string;
  entered_by: string;
  house_id: number;
  quad_id: number;
  timestamp: string;
  response: string;
  comment: string;
  shopify_id: string;
}

function matchesSearch(tag: Tag, searchTerm: string) {
  const fields = [
    tag.address,
    tag.name,
    tag.email,
    tag.phone,
    tag.scout,
    tag.entered_by,
    tag.house_id.toString(),
    tag.timestamp,
    tag.response,
    tag.comment,
  ];
  return fields.some((field) => field && field.toLowerCase().includes(searchTerm));
}

class TagList extends Component<TagListProps, TagListState> {
  constructor(props: TagListProps) {
    super(props);
    this.state = {
      tags: [],
      loaded: false,
      searchTerm: "",
      allTypes: false,
      onlyWithComment: false,
    };
  }

  loadTags() {
    callAPI(this.getURL())
      .then((response) => {
        this.setState({
          tags: response.results,
          loaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getURL() {
    let url = "list/";
    if (this.state.allTypes && !this.state.onlyWithComment) {
      url += "?all_types=true";
    } else if (this.state.allTypes && this.state.onlyWithComment) {
      url += "?all_types=true&comment_only=true";
    } else if (!this.state.allTypes && this.state.onlyWithComment) {
      url += "?comment_only=true";
    }
    return url;
  }

  componentDidMount() {
    this.loadTags();
  }

  interpretResponse(response: number) {
    const responses = ["Tree Tag", "Donation", "Refused", "No Answer", "Other"];
    return responses[response];
  }

  render() {
    return (
      <div className="container mt-2 mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="fw-normal">Your {this.state.allTypes ? "Responses" : "Tags"}</h1>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                value={this.state.searchTerm}
                onChange={(e) => {
                  this.setState({ searchTerm: e.currentTarget.value });
                }}
              />
            </div>
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => {
                unCacheAPI(this.getURL());
                this.loadTags();
              }}
            >
              Refresh
            </button>
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => {
                this.setState({ allTypes: !this.state.allTypes }, () => {
                  this.loadTags();
                });
              }}
            >
              {this.state.allTypes ? "Show Only Tags" : "Show All Types"}
            </button>
            <hr />
            <div className="mw-100" style={{ overflow: "auto" }}>
              {this.state.tags.length > 0 ? (
                <table className={"table " +
                  (this.props.theme === "dark" && "text-white")}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>House</th>
                      <th>Date</th>
                      <th>Response</th>
                      <th># Tags</th>
                      <th>Name</th>
                      <th>Money</th>
                      <th>
                        Comment{" "}
                        <span className="text-muted small">
                          (<button className="btn btn-link p-0" onClick={() => {
                            this.setState({ onlyWithComment: !this.state.onlyWithComment }, () => {
                              this.loadTags();
                            });
                          }}>
                            {this.state.onlyWithComment ? "clear filter" : "show only"}
                          </button>)
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tags.map((tag) => {
                      if (
                        this.state.searchTerm &&
                        !matchesSearch(tag, this.state.searchTerm)
                      ) {
                        return null;
                      }
                      return (
                        <tr>
                          <td>{tag.id}</td>
                          <td>
                            <Link
                              to={`/map/sales/${tag.quad_id}?house=${tag.house_id}`}
                            >
                              {tag.house_id}
                            </Link>
                          </td>
                          <td>{new Date(tag.timestamp).toLocaleDateString()}</td>
                          <td>{this.interpretResponse(parseInt(tag.response))}</td>
                          <td>{tag.tags}</td>
                          <td>{tag.name}</td>
                          <td>{tag.collected}</td>
                          <td>{(tag.comment || "") + (tag.shopify_id ? " (Ordered via Shopify)" : "")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">
                  {this.state.allTypes ? (
                    <p className="text-muted">No responses found.</p>
                  ) : (
                    <p className="text-muted">No tags found.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TagList;
